import parse from 'html-react-parser'

function Info({text}){
    return (
        <>
            <div className="info__txt">
                {parse(text)}
            </div>
        </>
    );
}

export default Info;

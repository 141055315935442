import parse from 'html-react-parser'
import { useKeenSlider } from "keen-slider/react"
import "keen-slider/keen-slider.min.css"
import React from "react";

function Docs({title, docs}){
    return (
        <>
            {title && (
                <div className="sc__title">
                    {parse(title)}
                </div>
            )}
            <div className="docs__list">
                <div className="row">
                    {docs.map((docIt, i) => (
                        <>
                            <div className="col col-6">
                                <div className="docs__it">
                                    <div className="docs__it-title">
                                        {docIt.title}
                                    </div>
                                    <div className="docs__it-download">
                                        <a href={docIt.file} target="_blank">
                                            скачать pdf
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </>
                    ))}
                </div>
            </div>
        </>
    );
}

export default Docs;

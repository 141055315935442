import CustomScroll from 'react-custom-scroll';
import {useModal} from "../context";
import {Link, useNavigate, Navigate} from 'react-router-dom'
import { useKeenSlider } from "keen-slider/react"
import "keen-slider/keen-slider.min.css"
import React, {useState, useRef, useEffect} from "react";
import parse from "html-react-parser";
import { useForm, SubmitHandler } from "react-hook-form";
import InputMask from "react-input-mask";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import Content from "./../components/modals/Content";
import Info from "../components/modals/Info";
import Rules from "../components/modals/Rules";
import Docs from "../components/modals/Docs";
import Files from "../components/modals/Files";
import Videos from "../components/modals/Videos";

function paginate(array, page_size, page_number) {
    return array.slice((page_number - 1) * page_size, page_number * page_size);
}

function Home() {
    const [dropDown1Open, setDropDown1Open] = useState(false);
    const [dropDown2Open, setDropDown2Open] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isSubmitting2, setIsSubmitting2] = useState(false);
    const [isSent2, setIsSent2] = useState(false);
    const [leftTab, setLeftTab] = useState((window.matchMedia("(max-width: 1023px)").matches ? 1 : 0));
    const [projects, setProjects] = useState([]);
    const [content, setContent] = useState(null);
    const [news, setNews] = useState([]);
    const [loaded, setLoaded] = useState(false);
    const [userData, setUserData] = useState({});
    const [currentSlide, setCurrentSlide] = useState(0);
    const [mbNav, setMbNav] = useState(false);
    const [sliderRef, instanceRef] = useKeenSlider({
        initial: 0,
        slides: {
            perView: 3,
            spacing: 0,
        },
        breakpoints: {
            '(max-width: 1023px)': {
                slides: {
                    perView: 1,
                    spacing: 0,
                },
            },
        },
        slideChanged(slider) {
            setCurrentSlide(slider.track.details.rel)
        },
        created() {
            setLoaded(true)
        },
    });
    const [loaded2, setLoaded2] = useState(false);
    const [currentSlide2, setCurrentSlide2] = useState(0);
    const [sliderRef2, instanceRef2] = useKeenSlider({
        initial: 0,
        slideChanged(slider) {
            setCurrentSlide2(slider.track.details.rel)
        },
        created() {
            setLoaded2(true)
        },
    });

    const [loaded3, setLoaded3] = useState(false);
    const [currentSlide3, setCurrentSlide3] = useState(0);
    const [sliderRef3, instanceRef3] = useKeenSlider({
        initial: 0,
        slideChanged(slider) {
            setCurrentSlide3(slider.track.details.rel)
        },
        created() {
            setLoaded3(true)
        },
    });

    const [selectOpen, setSelectOpen] = useState(false);
    const [selectProject, setSelectProject] = useState(null);

    const { show, hide} = useModal();

    useEffect(()=>{
        if(localStorage.getItem('auth_key')){
            getProfile();
        }
    }, []);

    const schemaReg = yup.object({
        sname: yup.string().required('Обязательно к заполнению'),
        name: yup.string().required('Обязательно к заполнению'),
        phone: yup.string().test('numbers', 'Только цифры', value => !value || /^[0-9]+$/.test(value))
            .test('lessThanTen', 'От 4 до 10 символов', (val) => {
                let res = true;
                if(val.length < 4){
                    res = false;
                }
                if(val.length > 10){
                    res = false;
                }

                return res;
            }).required('Только цифры'),
        project: yup.string().required('Обязательно к заполнению'),
        project_name: yup.string().nullable(),
        date: yup.string().test("len", 'Некорректная дата', (val) => {
            let _date = val.split('/');
            let res = true;
            let _nowYear = new Date().getFullYear();
            if(parseInt(_date[0]) > 31){
                res = false;
            }
            if(parseInt(_date[1]) > 12){
                res = false;
            }
            if((parseInt(_date[2]) < 2000) || (parseInt(_date[2]) > (parseInt(_nowYear) + 10))){
                res = false;
            }
            return res;
        }).required('Обязательно к заполнению'),
        broker: yup.string().nullable(),
        broker_username: yup.string().nullable(),
        agree1: yup.boolean().oneOf([true], 'Необходимо согласие'),
        agree2: yup.boolean().oneOf([true], 'Необходимо согласие'),
        agree3: yup.boolean().oneOf([true], 'Необходимо согласие'),
    }).required();

    const {
        register,
        setValue,
        setError,
        reset,
        formState: { errors },
        handleSubmit
    } = useForm({
        resolver: yupResolver(schemaReg)
    });

    if(!localStorage.getItem('auth_key')){
        return(<Navigate to="/login" replace />)
    }

    const getProfile = async() => {
        await fetch(process.env.REACT_APP_API_PROXY + 'wp/v2/getProfile', {
            method: "GET",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('auth_key')}`
            }
        })
            .then((response) => response.json())
            .then(response => {
                if(response.code === 'jwt_auth_invalid_token'){
                    localStorage.removeItem('auth_key');
                    window.location.reload();
                }else{
                    setValue('broker', response.user_id);
                    setValue('broker_username', response.user_login);
                    setUserData(response);
                    getContent();
                    getProjects();
                    getNews();
                }

            })
            .catch(error => {
                if(error.data && error.data.status === 403){
                    localStorage.removeItem('auth_key');
                    window.location.reload();
                }
            });
    }

    const getContent = async() => {
        await fetch(process.env.REACT_APP_API_PROXY + 'wp/v2/getContent', {
            method: "GET",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('auth_key')}`
            }
        })
            .then((response) => response.json())
            .then(response => {
                setContent(response);
            });
    }

    const getProjects = async() => {
        await fetch(process.env.REACT_APP_API_PROXY + 'wp/v2/getProjects', {
            method: "GET",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('auth_key')}`
            }
        })
            .then((response) => response.json())
            .then(response => {
                setProjects(response);
            });
    }

    const getNews = async() => {
        await fetch(process.env.REACT_APP_API_PROXY + 'wp/v2/posts', {
            method: "GET",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('auth_key')}`
            }
        })
            .then((response) => response.json())
            .then(response => {
                console.log(response);
                setNews(response);
            });
    }

    const onSubmit = async (data) => {
        setIsSubmitting(true);
        const formData = new FormData();
        formData.append("sname", data.sname);
        formData.append("name", data.name);
        formData.append("phone", data.phone.replace(/-|_| /g, ""));
        formData.append("project", data.project);
        formData.append("project_name", data.project_name);
        formData.append("date", data.date);
        formData.append("broker_username", data.broker_username);
        formData.append("broker_email", userData.user_email);
        formData.append("broker_first_name", userData.first_name);
        formData.append("broker_last_name", userData.last_name);
        formData.append("broker_phone", userData.user_phone);
        formData.append("broker_agency", userData.user_agency);
        formData.append("broker", data.broker);

        await fetch(process.env.REACT_APP_API_PROXY + 'wp/v2/registrationUser', {
            method: "POST",
            body: formData,
        }).then((response) => {
            if(response.ok){
                return response.json().then((data) => {
                    show(<Info text="Спасибо, заявка на&nbsp;регистрацию принята. По&nbsp;результатам проверки Вам придет письмо на&nbsp;указанный электронный адрес от&nbsp;broker@vespermoscow.com" close={hide}/>, "modal--info");
                    reset();
                    setValue('phone', '');
                    setValue('date', '');
                    setSelectProject(null);
                    setIsSubmitting(false);
                });
            }else{
                return response.json().then((error) => {
                    switch(error.code.toString()){
                        case 'jwt_auth_invalid_token':
                            localStorage.removeItem('auth_key');
                            window.location.reload();
                            break;

                        case '406':
                            setError('email', { type: 'custom', message: error.message});
                            break;

                        case '407':
                            setError('phone', { type: 'custom', message: error.message});
                            break;
                    }
                    setIsSubmitting(false);
                });

            }
        }).catch((error) => {
            setIsSubmitting(false);
        });
    }

    const createProjectRequest = async(projectId, projectName) => {
        setIsSubmitting2(true);
        const formData = new FormData();
        formData.append("broker_sname", userData.last_name);
        formData.append("broker_name", userData.first_name);
        formData.append("broker_phone", userData.user_phone);
        formData.append("broker_email", userData.user_email);
        formData.append("broker_agency", userData.user_agency);
        formData.append("project_id", projectId);
        formData.append("project_name", projectName);
        formData.append("broker_id", userData.user_id);

        await fetch(process.env.REACT_APP_API_PROXY + 'wp/v2/createProjectRequest', {
            method: "POST",
            body: formData,
        }).then((response) => {
            if(response.ok){
                return response.json().then((data) => {
                    setIsSent2(true);
                    setIsSubmitting2(false);
                });
            }else{
                return response.json().then((error) => {
                    switch(error.code.toString()){
                        case 'jwt_auth_invalid_token':
                            localStorage.removeItem('auth_key');
                            window.location.reload();
                            break;
                    }
                    setIsSubmitting2(false);
                });
            }
        }).catch((error) => {
            setIsSubmitting2(false);
        });
    }

    return (
        <div className="sc__main" id="main">
            <div className="sc__main-left">
                <div className="logo">
                    <svg width="115" height="20" viewBox="0 0 115 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M44.2738 2.16133C45.6203 0.308783 48.3653 -0.251515 50.4733 0.0982664C51.9606 0.345488 53.1983 1.07798 54.1863 2.29573C54.1945 2.30621 54.2006 2.31831 54.2041 2.33128C54.2077 2.34425 54.2086 2.35783 54.2068 2.37118C54.205 2.38453 54.2006 2.39736 54.1938 2.40889C54.1871 2.42042 54.1781 2.43041 54.1674 2.43824L52.8556 3.41471C52.8331 3.43164 52.8048 3.43859 52.7771 3.43404C52.7493 3.42949 52.7242 3.4138 52.7074 3.39042C51.3925 1.5735 49.1268 1.1719 47.126 1.99453C45.4201 2.69409 44.6838 4.31831 45.111 6.17895C45.4327 7.58293 46.6294 8.33593 47.8749 8.75373C50.9321 9.77878 54.046 10.3018 54.4922 13.8984C54.8312 16.6367 53.379 18.797 50.88 19.6342C48.124 20.5588 44.6049 19.7265 42.8343 17.1841C42.8278 17.1747 42.8232 17.1641 42.8208 17.1528C42.8184 17.1415 42.8182 17.1299 42.8203 17.1186C42.8223 17.1072 42.8266 17.0964 42.8328 17.0868C42.839 17.0772 42.847 17.069 42.8564 17.0626L44.3196 16.0538C44.3317 16.0454 44.3453 16.0397 44.3597 16.0369C44.374 16.0341 44.3888 16.0343 44.4031 16.0375C44.4173 16.0406 44.4308 16.0467 44.4428 16.0554C44.4547 16.064 44.4648 16.075 44.4725 16.0878C45.1862 17.2483 46.1974 17.9695 47.506 18.2512C49.8505 18.7549 52.5198 17.712 52.7847 15.0125C53.1142 11.6799 49.6707 11.2767 47.4729 10.4233C45.923 9.82088 44.7894 9.3286 44.0027 8.02339C42.9321 6.24534 43.0346 3.86489 44.2738 2.16133Z" fill="#2F3338"/>
                        <path d="M7.85298 17.0124L13.797 0.527367C13.8018 0.514564 13.8102 0.503539 13.8212 0.495737C13.8321 0.487935 13.8451 0.48372 13.8585 0.483645L15.5408 0.465832C15.5516 0.465627 15.5623 0.468158 15.572 0.473203C15.5817 0.478247 15.59 0.485651 15.5962 0.494765C15.6024 0.503878 15.6064 0.514422 15.6077 0.525471C15.609 0.53652 15.6077 0.547737 15.6038 0.558135L8.61294 19.4479C8.60817 19.4607 8.59975 19.4718 8.58877 19.4796C8.5778 19.4874 8.56479 19.4916 8.55145 19.4916L6.92591 19.5014C6.91256 19.5013 6.89955 19.4971 6.88858 19.4893C6.87761 19.4815 6.86918 19.4704 6.86442 19.4576L0.00437151 0.561374C0.000498259 0.550975 -0.000830328 0.539759 0.000502393 0.52871C0.00183511 0.517661 0.00578856 0.507117 0.0120153 0.498003C0.0182421 0.48889 0.0265518 0.481486 0.0362152 0.476441C0.0458785 0.471397 0.0565997 0.468866 0.0674378 0.469071L1.77181 0.475548C1.78515 0.475623 1.79816 0.479838 1.80914 0.48764C1.82011 0.495442 1.82853 0.506467 1.8333 0.51927L7.72843 17.0124C7.73306 17.0256 7.74152 17.0369 7.75266 17.045C7.76381 17.053 7.77709 17.0573 7.79071 17.0573C7.80432 17.0573 7.81761 17.053 7.82875 17.045C7.8399 17.0369 7.84836 17.0256 7.85298 17.0124Z" fill="#2F3338"/>
                        <path d="M25.1742 17.8804L35.0078 17.8788C35.0321 17.8788 35.0553 17.8887 35.0725 17.9063C35.0896 17.9239 35.0993 17.9478 35.0993 17.9727V19.4042C35.0993 19.4165 35.0969 19.4288 35.0923 19.4402C35.0877 19.4516 35.081 19.4619 35.0725 19.4706C35.064 19.4794 35.0539 19.4863 35.0428 19.491C35.0317 19.4957 35.0198 19.4981 35.0078 19.4981H23.4446C23.4326 19.4981 23.4207 19.4957 23.4096 19.491C23.3985 19.4863 23.3884 19.4794 23.3799 19.4706C23.3714 19.4619 23.3647 19.4516 23.3601 19.4402C23.3555 19.4288 23.3531 19.4165 23.3531 19.4042V0.564626C23.3531 0.539716 23.3628 0.515827 23.3799 0.498213C23.3971 0.480599 23.4203 0.470703 23.4446 0.470703H34.5727C34.5969 0.470703 34.6202 0.480599 34.6373 0.498213C34.6545 0.515827 34.6641 0.539716 34.6641 0.564626L34.6673 1.99614C34.6673 2.02105 34.6576 2.04494 34.6405 2.06255C34.6233 2.08016 34.6001 2.09006 34.5758 2.09006H25.1805C25.1685 2.09006 25.1566 2.09249 25.1455 2.09721C25.1344 2.10193 25.1243 2.10885 25.1158 2.11757C25.1073 2.12629 25.1006 2.13664 25.096 2.14804C25.0914 2.15943 25.0891 2.17165 25.0891 2.18398L25.0827 8.75857C25.0827 8.78348 25.0924 8.80737 25.1095 8.82498C25.1267 8.84259 25.1499 8.85249 25.1742 8.85249L33.983 8.84925C33.995 8.84925 34.0069 8.85168 34.018 8.8564C34.0291 8.86112 34.0392 8.86804 34.0477 8.87676C34.0561 8.88548 34.0629 8.89584 34.0675 8.90723C34.0721 8.91863 34.0744 8.93084 34.0744 8.94317V10.3779C34.0744 10.3903 34.0721 10.4025 34.0675 10.4139C34.0629 10.4253 34.0561 10.4356 34.0477 10.4443C34.0392 10.4531 34.0291 10.46 34.018 10.4647C34.0069 10.4694 33.995 10.4718 33.983 10.4718L25.1821 10.467C25.1578 10.467 25.1346 10.4769 25.1174 10.4945C25.1003 10.5121 25.0906 10.536 25.0906 10.5609L25.0827 17.7865C25.0827 17.8114 25.0924 17.8353 25.1095 17.8529C25.1267 17.8705 25.1499 17.8804 25.1742 17.8804Z" fill="#2F3338"/>
                        <path d="M65.01 10.4799C64.9908 10.4799 64.9724 10.4878 64.9588 10.5018C64.9452 10.5157 64.9375 10.5347 64.9375 10.5544V19.3994C64.9375 19.4119 64.9352 19.4243 64.9306 19.4359C64.926 19.4475 64.9192 19.458 64.9107 19.4669C64.9022 19.4758 64.8922 19.4828 64.8811 19.4876C64.87 19.4924 64.8581 19.4949 64.8461 19.4949H63.3341C63.3098 19.4949 63.2866 19.485 63.2694 19.4674C63.2523 19.4498 63.2426 19.4259 63.2426 19.401V0.545186C63.2426 0.526718 63.2498 0.509008 63.2625 0.495949C63.2752 0.48289 63.2924 0.475554 63.3104 0.475554C65.0962 0.463678 66.8868 0.465838 68.6821 0.482031C71.5106 0.507941 74.3943 1.82934 74.5157 5.16683C74.6387 8.56586 72.3053 10.3714 69.1835 10.433C67.7886 10.46 66.3975 10.4756 65.01 10.4799ZM69.2402 8.83791C70.5205 8.81686 72.2895 8.05576 72.6285 6.62749C73.3537 3.57986 71.2647 2.09815 68.6427 2.09167C67.4392 2.08843 66.2409 2.08843 65.0479 2.09167C65.0185 2.0921 64.9904 2.1044 64.9698 2.12591C64.9491 2.14743 64.9375 2.17643 64.9375 2.20665V8.73103C64.9375 8.76039 64.9488 8.78857 64.9688 8.80947C64.9889 8.83038 65.0162 8.84234 65.0447 8.84277C66.4511 8.86436 67.8496 8.86274 69.2402 8.83791Z" fill="#2F3338"/>
                        <path d="M84.6553 17.8788H94.4842C94.5084 17.8788 94.5317 17.8887 94.5488 17.9063C94.566 17.9239 94.5756 17.9478 94.5756 17.9727L94.5803 19.4042C94.5803 19.4165 94.578 19.4288 94.5734 19.4402C94.5688 19.4516 94.562 19.4619 94.5536 19.4706C94.5451 19.4794 94.535 19.4863 94.5239 19.491C94.5128 19.4957 94.5009 19.4981 94.4889 19.4981H82.9241C82.9121 19.4981 82.9002 19.4957 82.8891 19.491C82.878 19.4863 82.8679 19.4794 82.8594 19.4706C82.8509 19.4619 82.8442 19.4516 82.8396 19.4402C82.835 19.4288 82.8326 19.4165 82.8326 19.4042V0.564626C82.8326 0.539716 82.8423 0.515827 82.8594 0.498213C82.8766 0.480599 82.8998 0.470703 82.9241 0.470703H94.0916C94.1158 0.470703 94.1391 0.480599 94.1562 0.498213C94.1734 0.515827 94.183 0.539716 94.183 0.564626L94.1862 1.99614C94.1862 2.02105 94.1765 2.04494 94.1594 2.06255C94.1422 2.08016 94.119 2.09006 94.0947 2.09006H84.6553C84.631 2.09006 84.6078 2.09995 84.5906 2.11757C84.5735 2.13518 84.5638 2.15907 84.5638 2.18398L84.5685 8.75857C84.5685 8.78348 84.5782 8.80737 84.5953 8.82498C84.6125 8.84259 84.6357 8.85249 84.66 8.85249L93.4609 8.84925C93.4852 8.84925 93.5084 8.85915 93.5256 8.87676C93.5427 8.89438 93.5524 8.91826 93.5524 8.94317L93.5555 10.3763C93.5555 10.3886 93.5531 10.4009 93.5485 10.4122C93.5439 10.4236 93.5372 10.434 93.5287 10.4427C93.5202 10.4514 93.5102 10.4584 93.4991 10.4631C93.488 10.4678 93.4761 10.4702 93.4641 10.4702H84.66C84.6357 10.4702 84.6125 10.4801 84.5953 10.4977C84.5782 10.5154 84.5685 10.5392 84.5685 10.5641L84.5638 17.7849C84.5638 17.8098 84.5735 17.8337 84.5906 17.8513C84.6078 17.8689 84.631 17.8788 84.6553 17.8788Z" fill="#2F3338"/>
                        <path d="M109.787 10.3504C109.771 10.3525 109.756 10.3588 109.742 10.3686C109.729 10.3784 109.718 10.3915 109.711 10.4066C109.704 10.4218 109.701 10.4385 109.702 10.4553C109.703 10.472 109.708 10.4883 109.716 10.5026L114.985 19.4237C115.014 19.4722 115.001 19.4965 114.946 19.4965L113.164 19.4998C113.14 19.4998 113.116 19.4933 113.095 19.4808C113.073 19.4683 113.056 19.4503 113.043 19.4285L107.917 10.5399C107.905 10.5187 107.887 10.5011 107.866 10.4889C107.845 10.4767 107.822 10.4703 107.797 10.4702H104.728C104.715 10.4702 104.702 10.4729 104.69 10.478C104.678 10.4832 104.667 10.4907 104.658 10.5002C104.649 10.5097 104.642 10.521 104.637 10.5333C104.632 10.5457 104.63 10.5589 104.63 10.5722L104.632 19.401C104.632 19.4267 104.622 19.4515 104.604 19.4697C104.586 19.4879 104.562 19.4981 104.537 19.4981H103.034C103.008 19.4981 102.983 19.4874 102.964 19.4683C102.946 19.4491 102.935 19.4232 102.935 19.3961V0.546816C102.935 0.529208 102.942 0.51232 102.954 0.499869C102.966 0.487418 102.983 0.480423 103 0.480423C104.985 0.453433 106.974 0.46207 108.966 0.506332C110.514 0.540339 112.486 1.1897 113.481 2.51757C114.732 4.18875 114.7 7.06796 113.314 8.65655C112.471 9.62331 111.06 10.1739 109.787 10.3504ZM112.01 7.44365C113.617 5.12959 112.086 2.3945 109.47 2.16617C108.224 2.05714 106.641 2.03932 104.723 2.11273C104.698 2.11357 104.674 2.12452 104.657 2.14326C104.64 2.162 104.63 2.18706 104.63 2.21313V8.76019C104.63 8.7831 104.639 8.80511 104.655 8.82146C104.671 8.83781 104.692 8.84721 104.715 8.84764C106.67 8.86221 110.687 9.35125 112.01 7.44365Z" fill="#2F3338"/>
                    </svg>
                </div>
                {leftTab == 0 && (
                    <div onClick={()=>{
                        setLeftTab(1);
                    }} className="nav__bars desk-only">
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M3 6.00098H21M3 12.001H21M3 18.001H21" stroke="#6A6A6A" strokeLinecap="round" strokeLinejoin="round"/>
                        </svg>
                    </div>
                )}

                {leftTab == 1 && (
                    <div onClick={()=>{
                        setLeftTab(0);
                    }} className="nav__bars desk-only">
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M19.2806 18.2193C19.3502 18.289 19.4055 18.3717 19.4432 18.4628C19.4809 18.5538 19.5003 18.6514 19.5003 18.7499C19.5003 18.8485 19.4809 18.9461 19.4432 19.0371C19.4055 19.1281 19.3502 19.2109 19.2806 19.2806C19.2109 19.3502 19.1281 19.4055 19.0371 19.4432C18.9461 19.4809 18.8485 19.5003 18.7499 19.5003C18.6514 19.5003 18.5538 19.4809 18.4628 19.4432C18.3717 19.4055 18.289 19.3502 18.2193 19.2806L11.9999 13.0602L5.78055 19.2806C5.63982 19.4213 5.44895 19.5003 5.24993 19.5003C5.05091 19.5003 4.86003 19.4213 4.7193 19.2806C4.57857 19.1398 4.49951 18.949 4.49951 18.7499C4.49951 18.5509 4.57857 18.36 4.7193 18.2193L10.9396 11.9999L4.7193 5.78055C4.57857 5.63982 4.49951 5.44895 4.49951 5.24993C4.49951 5.05091 4.57857 4.86003 4.7193 4.7193C4.86003 4.57857 5.05091 4.49951 5.24993 4.49951C5.44895 4.49951 5.63982 4.57857 5.78055 4.7193L11.9999 10.9396L18.2193 4.7193C18.36 4.57857 18.5509 4.49951 18.7499 4.49951C18.949 4.49951 19.1398 4.57857 19.2806 4.7193C19.4213 4.86003 19.5003 5.05091 19.5003 5.24993C19.5003 5.44895 19.4213 5.63982 19.2806 5.78055L13.0602 11.9999L19.2806 18.2193Z" fill="#6A6A6A"/>
                        </svg>
                    </div>
                )}

                <div onClick={()=>{
                    setMbNav(!mbNav);
                    setLeftTab(1);
                }} className="nav__bars mb-only">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        {!mbNav && (
                            <path d="M3 6.00098H21M3 12.001H21M3 18.001H21" stroke="#6A6A6A" strokeLinecap="round" strokeLinejoin="round"/>
                        )}

                        {mbNav && (
                            <path d="M19.2806 18.2193C19.3502 18.289 19.4055 18.3717 19.4432 18.4628C19.4809 18.5538 19.5003 18.6514 19.5003 18.7499C19.5003 18.8485 19.4809 18.9461 19.4432 19.0371C19.4055 19.1281 19.3502 19.2109 19.2806 19.2806C19.2109 19.3502 19.1281 19.4055 19.0371 19.4432C18.9461 19.4809 18.8485 19.5003 18.7499 19.5003C18.6514 19.5003 18.5538 19.4809 18.4628 19.4432C18.3717 19.4055 18.289 19.3502 18.2193 19.2806L11.9999 13.0602L5.78055 19.2806C5.63982 19.4213 5.44895 19.5003 5.24993 19.5003C5.05091 19.5003 4.86003 19.4213 4.7193 19.2806C4.57857 19.1398 4.49951 18.949 4.49951 18.7499C4.49951 18.5509 4.57857 18.36 4.7193 18.2193L10.9396 11.9999L4.7193 5.78055C4.57857 5.63982 4.49951 5.44895 4.49951 5.24993C4.49951 5.05091 4.57857 4.86003 4.7193 4.7193C4.86003 4.57857 5.05091 4.49951 5.24993 4.49951C5.44895 4.49951 5.63982 4.57857 5.78055 4.7193L11.9999 10.9396L18.2193 4.7193C18.36 4.57857 18.5509 4.49951 18.7499 4.49951C18.949 4.49951 19.1398 4.57857 19.2806 4.7193C19.4213 4.86003 19.5003 5.05091 19.5003 5.24993C19.5003 5.44895 19.4213 5.63982 19.2806 5.78055L13.0602 11.9999L19.2806 18.2193Z" fill="#6A6A6A"/>
                        )}
                    </svg>
                </div>

                <div className={'sc__main-left-content' + (mbNav ? ' active' : '')}>
                    <div className="sc__main-left-tabs">
                        <div className={'left__tab fadeIn animated' + (leftTab == 0 ? ' active' : '')}>
                            <Link to="/configurator" className="btn btn--dark btn--small d-flex align-items-center justify-content-center">
                                Подбор по параметрам
                            </Link>
                            <div className="left__form fadeIn animated">
                                <div className="left__form-title">
                                    регистрация клиента
                                </div>
                                <form onSubmit={handleSubmit(onSubmit)} className={'client__form' + (isSubmitting ? ' form--loading' : '')}>
                                    <div className="form__it">
                                        <div className="form__lbl">
                                            фамилия клиента
                                        </div>
                                        <div className={'form__field' + (errors.sname ? ' has-error' : '')}>
                                            <input
                                                type="text"
                                                placeholder=""
                                                {...register("sname")}
                                            />
                                        </div>
                                        {errors.sname && (
                                            <p className="error__message">
                                                {errors.sname?.message}
                                            </p>
                                        )}
                                    </div>
                                    <div className="form__it">
                                        <div className="form__lbl">
                                            имя клиента
                                        </div>
                                        <div className={'form__field' + (errors.name ? ' has-error' : '')}>
                                            <input
                                                type="text"
                                                placeholder=""
                                                {...register("name")}
                                            />
                                        </div>
                                        {errors.name && (
                                            <p className="error__message">
                                                {errors.name?.message}
                                            </p>
                                        )}
                                    </div>
                                    <div className="form__it">
                                        <div className="form__lbl">
                                            телефон клиента <span>*</span>
                                        </div>
                                        <div className="form__field">
                                            <div className={'form__field' + (errors.phone ? ' has-error' : '')}>
                                                <input
                                                    type="text"
                                                    {...register("phone")}
                                                    placeholder=""
                                                    max={10}
                                                    onKeyDown={(event) => {
                                                        if ( event.keyCode == 46 || event.keyCode == 8 || event.keyCode == 9 || event.keyCode == 27 || event.keyCode == 13 ||
                                                            // Allow: Ctrl+A
                                                            (event.keyCode == 65 && event.ctrlKey === true) ||
                                                            // Allow: home, end, left, right
                                                            (event.keyCode >= 35 && event.keyCode <= 39)) {
                                                            // let it happen, don't do anything
                                                            return;
                                                        }
                                                        else {
                                                            // Ensure that it is a number and stop the keypress
                                                            if (event.shiftKey || (event.keyCode < 48 || event.keyCode > 57) && (event.keyCode < 96 || event.keyCode > 105 )) {
                                                                event.preventDefault();
                                                            }
                                                        }
                                                    }}
                                                />
                                            </div>
                                            {errors.phone && (
                                                <p className="error__message">
                                                    {errors.phone?.message}
                                                </p>
                                            )}
                                        </div>
                                    </div>
                                    <div className="form__it">
                                        <div className="form__lbl">
                                            проект
                                        </div>
                                        <div className={'form__field form__field--select ' + (errors.project ? ' has-error' : '')
                                            + (selectOpen ? ' active' : '')}>
                                            <input
                                                type="text"
                                                placeholder="Bыбрать"
                                                value={selectProject ?? ''}
                                            />
                                            <div onClick={()=>{
                                                setSelectOpen(true);
                                            }} className="select__toggle"></div>
                                            <div className="select__dropdown">
                                                <div className="select__list">
                                                    {projects.filter((prIt) => prIt.form === true).map((pr, i) => (
                                                        <div onClick={()=>{
                                                            setSelectOpen(false);
                                                            setSelectProject(pr.title);
                                                            setValue('project', pr.id);
                                                            setValue('project_name', pr.title);
                                                        }} className="select__it">
                                                            {pr.title}
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                        </div>
                                        {errors.project && (
                                            <p className="error__message">
                                                {errors.project?.message}
                                            </p>
                                        )}
                                    </div>
                                    <div className="form__it">
                                        <div className="form__lbl">
                                            дата презентации
                                        </div>
                                        <div className={'form__field' + (errors.date ? ' has-error' : '')}>
                                            <InputMask
                                                autoComplete="nope"
                                                type="text"
                                                mask="99/99/9999"
                                                alwaysShowMask={false}
                                                {...register("date")}
                                            />
                                        </div>
                                        {errors.date && (
                                            <p className="error__message">
                                                {errors.date?.message}
                                            </p>
                                        )}
                                        <div className="form__it-hint d-flex align-items-start">
                                            <span>*</span>
                                            {content && content.phone_hint && (
                                                <div>
                                                    {parse(content.phone_hint)}
                                                </div>
                                            )}

                                        </div>
                                    </div>
                                    <div className="form__it form__it--indent">
                                        <div className={'checkbox__wrap' + (errors.agree1 ? ' has-error' : '')}>
                                            <label>
                                                <input type="checkbox" defaultChecked={true} {...register("agree1")}/>
                                                <span className="checkbox__decor"></span>
                                                <span className="checkbox__title">
                                                    Я согласен на обработку <br/>
                                                    <a onClick={(e)=>{
                                                        if(content.policy){
                                                            show(<Content text={content.policy} close={hide}/>, "modal--content");
                                                            e.preventDefault();
                                                            e.stopPropagation();
                                                        }
                                                    }}>персональных данных</a>
                                                </span>
                                            </label>
                                            {errors.agree1 && (
                                                <p className="error__message">
                                                    {errors.agree1?.message}
                                                </p>
                                            )}
                                        </div>
                                    </div>
                                    <div className="form__it">
                                        <div className={'checkbox__wrap' + (errors.agree2 ? ' has-error' : '')}>
                                            <label>
                                                <input type="checkbox" defaultChecked={true} {...register("agree2")}/>
                                                <span className="checkbox__decor"></span>
                                                <span className="checkbox__title">
                                                    Я ознакомлен и согласен <br/>
                                                    с <a onClick={(e)=>{
                                                        if(content.brokers) {
                                                            show(<Content text={content.brokers} close={hide}/>, "modal--content");
                                                            e.preventDefault();
                                                            e.stopPropagation();
                                                        }
                                                }}>регламентом брокеров</a>
                                                </span>
                                            </label>
                                            {errors.agree2 && (
                                                <p className="error__message">
                                                    {errors.agree2?.message}
                                                </p>
                                            )}
                                        </div>
                                    </div>
                                    <div className="form__it">
                                        <div className={'checkbox__wrap' + (errors.agree3 ? ' has-error' : '')}>
                                            <label>
                                                <input type="checkbox" defaultChecked={true}  {...register("agree3")}/>
                                                <span className="checkbox__decor"></span>
                                                <span className="checkbox__title">
                                                    Я ознакомлен и согласен <br/>
                                                    с <a onClick={(e)=>{
                                                        if(content.reg){
                                                            show(<Content text={content.reg} close={hide}/>, "modal--content");
                                                            e.preventDefault();
                                                            e.stopPropagation();
                                                        }
                                                }}>условиями регистрации клиентов</a>
                                                </span>
                                            </label>
                                            {errors.agree3 && (
                                                <p className="error__message">
                                                    {errors.agree3?.message}
                                                </p>
                                            )}
                                        </div>
                                    </div>
                                    <div className="form__it form__it--actions">
                                        <button type="submit" className="btn btn--full btn--dark tbn--small d-flex align-items-center justify-content-center">
                                            Отправить
                                        </button>
                                    </div>
                                </form>
                            </div>
                            <div className="d-flex justify-content-center mb-only">
                                <a onClick={()=>{
                                    setLeftTab(1);
                                    document.getElementById('main').scrollIntoView();
                                }} className="back__link">назад</a>
                            </div>
                        </div>
                        <div className={'left__tab fadeIn animated' + (leftTab == 1 ? ' active' : '')}>
                            <div className="left__nav">
                                <ul>
                                    <li>
                                        <a onClick={()=>{
                                            setLeftTab(0);
                                        }}>
                                            регистрация клиента
                                        </a>
                                    </li>
                                    <li>
                                        <Link to="/configurator">
                                            подбор по параметрам
                                        </Link>
                                    </li>
                                    <li className={(dropDown1Open ? 'active' : '')}>
                                        <a onClick={()=>{
                                            setDropDown1Open(!dropDown1Open);
                                        }}>
                                            прайс-листы
                                        </a>
                                        {dropDown1Open && (
                                            <ul className="fadeIn animated">
                                                {content && content.prices.map((prNav, j) => (
                                                    <li>
                                                        {prNav.pr_nav_type === 'widjet' && (
                                                            <a onClick={()=>{
                                                                show(<Files title={prNav.pr_nav_title} file_manager_link={prNav.file_manager_link} close={hide}/>, "modal--frame");
                                                            }}>{prNav.pr_nav_title}</a>
                                                        )}

                                                        {prNav.pr_nav_type === 'ext' && (
                                                            <a href={prNav.ext_link} target="_blank">{prNav.pr_nav_title}</a>
                                                        )}

                                                        {prNav.pr_nav_type === 'ymap_link' && (
                                                            <a href={prNav.ymap_link} target="_blank">{prNav.pr_nav_title}</a>
                                                        )}

                                                        {prNav.pr_nav_type === 'pdf' && (
                                                            <a href={prNav.file} target="_blank">{prNav.pr_nav_title}</a>
                                                        )}
                                                    </li>
                                                ))}
                                            </ul>
                                        )}
                                    </li>
                                    {content && content.video && (
                                        <li>
                                            <a onClick={()=>{
                                                show(<Videos close={hide}/>, "modal--video");
                                            }}>
                                                видео по проектам
                                            </a>
                                        </li>
                                    )}

                                    <li className={(dropDown2Open ? 'active' : '')}>
                                        <a onClick={()=>{
                                            setDropDown2Open(!dropDown2Open);
                                        }}>
                                            документы
                                        </a>
                                        {dropDown2Open && (
                                            <ul className="fadeIn animated">
                                                {content && content.docs && (
                                                    <>
                                                        {content.docs.map((docIt, i) => (
                                                            <li>
                                                                {docIt.type.value === 'single' && (
                                                                    <a href={docIt.file} target="_blank">{docIt.title}</a>
                                                                )}
                                                                {docIt.type.value === 'multiple' && (
                                                                    <a onClick={()=>{
                                                                        show(<Docs title={docIt.group} docs={docIt.files} close={hide}/>, "modal--docs");
                                                                    }}>
                                                                        {parse(docIt.group)}
                                                                    </a>
                                                                )}
                                                            </li>
                                                        ))}
                                                    </>
                                                )}
                                            </ul>
                                        )}
                                    </li>

                                    <li>
                                        <a onClick={()=>{
                                            show(<Rules docs={content.docs} faq={content.faq} close={hide}/>, "modal--rules");
                                        }}>
                                            правила работы
                                        </a>
                                    </li>
                                    <li className="left__nav--indent">
                                        <a onClick={()=>{
                                            localStorage.removeItem('auth_key');
                                            window.location.reload();
                                        }}>
                                            Выйти
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>

                {content && (
                    <div className="sc__main-footer fadeIn animated desk-only">
                        <div className="side__phone text-center">
                            <a href={'tel:' + content.phone.replace(/-|_| /g, "").replace('(', '').replace(')', '')}>{content.phone}</a>
                        </div>
                        <div className="side__address text-center">
                            <a href={content.ymaps} target="_blank">
                                {content.address}
                            </a>
                        </div>
                        <div className="side__social">
                            <a href={content.telegram} target="_blank">
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M23.9997 11.9137C24.0104 13.4895 23.7106 15.0521 23.1174 16.5123C22.5242 17.9725 21.6493 19.3017 20.5426 20.424C19.4358 21.5463 18.119 22.4397 16.6672 23.0532C15.2154 23.6667 13.6571 23.9884 12.0813 23.9997C10.5055 24.011 8.94298 23.7119 7.48302 23.1193C6.02306 22.5267 4.69423 21.6524 3.5724 20.5461C2.45058 19.4398 1.55772 18.1234 0.944814 16.6718C0.331907 15.2203 0.0109527 13.6622 0.000275473 12.0863C-0.0104019 10.5105 0.289409 8.94788 0.882587 7.48767C1.47577 6.02746 2.3507 4.69827 3.45742 3.57598C4.56415 2.45369 5.881 1.56028 7.33279 0.946766C8.78458 0.333252 10.3429 0.0116455 11.9187 0.000310579C13.4945 -0.0110267 15.057 0.288129 16.517 0.880696C17.9769 1.47326 19.3058 2.34764 20.4276 3.45389C21.5494 4.56015 22.4423 5.87663 23.0552 7.32816C23.6681 8.77969 23.989 10.3378 23.9997 11.9137ZM22.9367 12.0393C22.9466 9.13894 21.8035 6.35318 19.7589 4.2949C17.7144 2.23663 14.9359 1.07443 12.0347 1.064C9.13346 1.05356 6.34715 2.19574 4.28872 4.23926C2.2303 6.28278 1.06837 9.06025 1.05854 11.9607C1.04872 14.8611 2.1918 17.6468 4.23633 19.7051C6.28087 21.7634 9.05936 22.9256 11.9606 22.936C14.8618 22.9464 17.6481 21.8043 19.7066 19.7607C21.765 17.7172 22.9269 14.9398 22.9367 12.0393Z" fill="#2F3338"/>
                                    <path d="M17.505 6.96072C17.5307 6.98407 17.5495 7.01458 17.5595 7.04892C17.5695 7.08326 17.5703 7.12012 17.5617 7.15548C16.8057 10.2149 16.0441 13.28 15.277 16.3508C15.0998 17.052 14.5777 17.5608 13.9067 17.0666C13.0593 16.445 12.2158 15.8217 11.3763 15.1969C11.3486 15.1762 11.3151 15.1656 11.2812 15.1669C11.2473 15.1683 11.215 15.1814 11.1896 15.2042L9.76964 16.4482C9.74342 16.4713 9.71249 16.4879 9.67915 16.497C9.64581 16.506 9.61093 16.5072 9.5771 16.5004C9.54326 16.4936 9.51135 16.479 9.48374 16.4578C9.45612 16.4365 9.43351 16.4091 9.4176 16.3776L7.80153 13.1543C7.78215 13.1142 7.75525 13.0785 7.7224 13.0493C7.68956 13.02 7.6514 12.9978 7.61015 12.9838L4.87891 12.0344C4.82115 12.0142 4.77139 11.9754 4.73724 11.9239C4.7031 11.8725 4.68647 11.8112 4.68989 11.7495C4.70092 11.4898 4.82772 11.3153 5.07028 11.2261C9.07895 9.73774 13.0829 8.23643 17.0821 6.72213C17.1546 6.69454 17.2955 6.77407 17.505 6.96072ZM9.64914 13.7483L14.8494 9.13478C14.8982 9.09259 14.8903 9.07148 14.8258 9.07148C14.788 9.07311 14.7564 9.08122 14.7312 9.09583C12.682 10.3375 10.6249 11.5799 8.55995 12.8232C8.52138 12.8466 8.49272 12.8841 8.47961 12.9283C8.46649 12.9724 8.46987 13.02 8.48907 13.0617L9.55227 15.3161C9.66568 15.558 9.70978 15.5458 9.68458 15.2796L9.56408 13.9698C9.55974 13.9286 9.56521 13.8868 9.58003 13.8482C9.59485 13.8096 9.61857 13.7753 9.64914 13.7483Z" fill="#2F3338"/>
                                </svg>
                            </a>
                            <a href={content.youtube} target="_blank">
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M23.9998 11.9352C24.0079 13.5111 23.7054 15.0732 23.1097 16.5323C22.5141 17.9914 21.6369 19.3191 20.5283 20.4394C19.4197 21.5597 18.1013 22.4507 16.6485 23.0616C15.1956 23.6725 13.6368 23.9913 12.061 23.9998C8.8784 24.017 5.81978 22.7692 3.55794 20.5309C1.2961 18.2927 0.0163288 15.2473 0.000154915 12.0648C-0.00785207 10.4889 0.294604 8.92684 0.890256 7.4677C1.48591 6.00855 2.36309 4.68093 3.47172 3.56063C4.58035 2.44033 5.89871 1.54929 7.35154 0.938386C8.80436 0.327482 10.3632 0.00867709 11.939 0.000174891C15.1216 -0.0169957 18.1802 1.2308 20.4421 3.46905C22.7039 5.70731 23.9837 8.75269 23.9998 11.9352ZM22.9027 11.8922C22.8782 8.99789 21.7052 6.23249 19.6419 4.20433C17.5786 2.17617 14.794 1.05138 11.9005 1.0774C10.4678 1.09029 9.05157 1.38532 7.73258 1.94565C6.41359 2.50597 5.21771 3.32063 4.21323 4.34309C3.20874 5.36556 2.41532 6.57581 1.87827 7.90475C1.34121 9.23369 1.07104 10.6553 1.08317 12.0884C1.10768 14.9827 2.2806 17.7481 4.3439 19.7762C6.40721 21.8044 9.19188 22.9292 12.0853 22.9031C13.518 22.8903 14.9343 22.5952 16.2533 22.0349C17.5723 21.4746 18.7681 20.6599 19.7726 19.6375C20.7771 18.615 21.5705 17.4047 22.1076 16.0758C22.6446 14.7469 22.9148 13.3253 22.9027 11.8922Z" fill="#2F3338"/>
                                    <path d="M19.8978 14.4606C19.8978 15.2273 19.6023 15.9625 19.0763 16.5046C18.5503 17.0467 17.8369 17.3512 17.093 17.3512H6.95891C6.21503 17.3512 5.50162 17.0467 4.97562 16.5046C4.44962 15.9625 4.15411 15.2273 4.15411 14.4606V9.54978C4.15411 8.78315 4.44962 8.04791 4.97562 7.50582C5.50162 6.96373 6.21503 6.65918 6.95891 6.65918H17.093C17.8369 6.65918 18.5503 6.96373 19.0763 7.50582C19.6023 8.04791 19.8978 8.78315 19.8978 9.54978V14.4606ZM13.8707 11.9712L10.3723 10.0409C10.3576 10.033 10.3412 10.0291 10.3247 10.0296C10.3081 10.0301 10.2919 10.0349 10.2777 10.0437C10.2635 10.0525 10.2517 10.0649 10.2435 10.0797C10.2353 10.0946 10.2309 10.1113 10.2308 10.1284V13.8869C10.231 13.9037 10.2354 13.9201 10.2435 13.9347C10.2515 13.9492 10.263 13.9614 10.2768 13.97C10.2906 13.9785 10.3062 13.9832 10.3221 13.9836C10.338 13.9839 10.3537 13.9799 10.3676 13.972L13.8707 11.9955C13.8832 11.9874 13.8832 11.9793 13.8707 11.9712Z" fill="#2F3338"/>
                                </svg>
                            </a>
                            <a href={content.vimeo} target="_blank">
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M24 12C24 15.1826 22.7357 18.2348 20.4853 20.4853C18.2348 22.7357 15.1826 24 12 24C8.8174 24 5.76515 22.7357 3.51471 20.4853C1.26428 18.2348 0 15.1826 0 12C0 8.8174 1.26428 5.76516 3.51471 3.51472C5.76515 1.26428 8.8174 0 12 0C15.1826 0 18.2348 1.26428 20.4853 3.51472C22.7357 5.76516 24 8.8174 24 12ZM22.9297 12C22.9297 9.10127 21.7781 6.32127 19.7284 4.27156C17.6787 2.22186 14.8987 1.07034 12 1.07034C10.5647 1.07034 9.14345 1.35305 7.8174 1.90231C6.49135 2.45158 5.28648 3.25665 4.27157 4.27156C3.25665 5.28648 2.45157 6.49135 1.90231 7.8174C1.35304 9.14345 1.07034 10.5647 1.07034 12C1.07034 13.4353 1.35304 14.8566 1.90231 16.1826C2.45157 17.5086 3.25665 18.7135 4.27157 19.7284C5.28648 20.7433 6.49135 21.5484 7.8174 22.0977C9.14345 22.647 10.5647 22.9297 12 22.9297C14.8987 22.9297 17.6787 21.7781 19.7284 19.7284C21.7781 17.6787 22.9297 14.8987 22.9297 12Z" fill="#2F3338"/>
                                    <path d="M13.1482 8.91296C13.4352 6.80876 16.5173 4.70214 18.4026 6.13737C20.2489 7.54341 18.4074 11.3091 17.4198 12.8003C16.0949 14.8047 14.4593 16.5124 12.5133 17.9233C10.825 19.1469 9.48224 17.1619 9.05167 15.7607C8.4046 13.646 7.7478 11.5848 7.08127 9.57706C6.95964 9.21379 6.72773 9.1173 6.38555 9.28758L5.45386 9.75464C5.41774 9.77326 5.37615 9.77859 5.33621 9.76972C5.29627 9.76084 5.26046 9.73831 5.23493 9.70599L4.8749 9.23893C4.84729 9.2025 4.83299 9.15764 4.83431 9.11169C4.83564 9.06573 4.85252 9.0214 4.8822 8.98594C5.8001 7.91073 6.85017 6.98715 8.03241 6.21521C8.32595 6.02385 8.64461 5.89978 8.98842 5.84302C10.4918 5.59003 10.881 7.19068 11.0415 8.29751C11.2994 10.0506 11.7373 11.7567 12.3551 13.4157C12.3833 13.4893 12.4379 13.5499 12.5084 13.586C13.3987 14.0336 14.885 10.8298 15.031 10.3433C15.3837 9.16838 14.2842 8.39968 13.2382 8.97377C13.1684 9.0127 13.1384 8.99242 13.1482 8.91296Z" fill="#2F3338"/>
                                </svg>
                            </a>
                            <a href={content.itunes} target="_blank">
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M24 12C24 13.5759 23.6896 15.1363 23.0866 16.5922C22.4835 18.0481 21.5996 19.371 20.4853 20.4853C19.371 21.5996 18.0481 22.4835 16.5922 23.0866C15.1363 23.6896 13.5759 24 12 24C8.81741 24 5.76516 22.7357 3.51472 20.4853C1.26428 18.2348 0 15.1826 0 12C0 8.8174 1.26428 5.76516 3.51472 3.51472C5.76516 1.26428 8.81741 0 12 0C15.1826 0 18.2348 1.26428 20.4853 3.51472C22.7357 5.76516 24 8.8174 24 12ZM22.7517 11.9879C22.7517 9.13698 21.6192 6.40287 19.6033 4.387C17.5874 2.37112 14.8533 1.23862 12.0024 1.23862C9.15156 1.23862 6.41745 2.37112 4.40157 4.387C2.3857 6.40287 1.2532 9.13698 1.2532 11.9879C1.2532 14.8387 2.3857 17.5728 4.40157 19.5887C6.41745 21.6046 9.15156 22.7371 12.0024 22.7371C14.8533 22.7371 17.5874 21.6046 19.6033 19.5887C21.6192 17.5728 22.7517 14.8387 22.7517 11.9879Z" fill="#2F3338"/>
                                    <path d="M10.4626 13.329L12.3011 13.3338C12.3395 13.3338 12.3772 13.3439 12.4105 13.3631C12.4437 13.3823 12.4714 13.4099 12.4905 13.4431L13.1876 14.6477C13.1953 14.6606 13.1995 14.6754 13.1997 14.6904C13.1999 14.7054 13.196 14.7202 13.1886 14.7333C13.1812 14.7464 13.1705 14.7573 13.1575 14.7649C13.1445 14.7725 13.1297 14.7765 13.1147 14.7765C11.0876 14.7943 9.06776 14.7959 7.05521 14.7813C6.3849 14.7765 5.88459 14.4122 6.26103 13.703C6.38732 13.465 6.58567 13.3444 6.85606 13.3411L8.5464 13.3266C8.58465 13.3265 8.62235 13.3162 8.65563 13.2966C8.6889 13.277 8.71658 13.2488 8.73584 13.2148L11.0431 9.17598C11.0599 9.14649 11.0686 9.11313 11.0681 9.07937C11.0677 9.04562 11.0582 9.01271 11.0406 8.98411C10.6877 8.40285 10.4861 8.04665 10.4359 7.9155C9.98903 6.74975 11.6235 6.38059 11.8518 7.50263C11.868 7.58359 11.9036 7.5933 11.9587 7.53178C12.3545 7.09219 12.7116 6.3563 13.4134 7.06304C13.6369 7.28648 13.6701 7.53582 13.513 7.81107L10.4116 13.2391C10.4064 13.2485 10.4037 13.259 10.4037 13.2697C10.4037 13.2804 10.4064 13.2908 10.4116 13.2999C10.4168 13.3091 10.4242 13.3165 10.4332 13.3216C10.4422 13.3268 10.4523 13.3293 10.4626 13.329Z" fill="#2F3338"/>
                                    <path d="M15.4414 13.3799C16.2356 13.3994 18.2125 12.9889 17.7948 14.5117C17.7731 14.5894 17.7217 14.6556 17.6515 14.6963C17.224 14.9472 16.7593 14.9999 16.2574 14.8541C16.1505 14.8218 16.1287 14.8517 16.1918 14.944C16.697 15.6872 17.5179 16.6732 16.4566 17.1516C16.1149 17.3055 15.8494 17.2205 15.66 16.8966L12.6144 11.6726C12.5946 11.6388 12.5843 11.6002 12.5847 11.5609C12.5852 11.5215 12.5963 11.4829 12.6169 11.4492L13.4037 10.1814C13.4088 10.1729 13.4161 10.166 13.4247 10.1612C13.4334 10.1564 13.4431 10.1541 13.453 10.1543C13.4629 10.1546 13.4725 10.1574 13.4809 10.1626C13.4893 10.1678 13.4962 10.1751 13.5009 10.1838L15.2568 13.2706C15.2753 13.3032 15.3021 13.3303 15.3344 13.3495C15.3668 13.3686 15.4036 13.3791 15.4414 13.3799Z" fill="#2F3338"/>
                                    <path d="M7.23979 15.9417C7.2635 15.9046 7.29641 15.8739 7.33546 15.8527C7.37451 15.8315 7.41845 15.8204 7.46324 15.8203H8.84758C8.97063 15.8203 8.99896 15.8721 8.93257 15.9757L8.30113 16.9448C8.05179 17.3269 7.75225 17.3714 7.40252 17.0784C7.01718 16.7562 6.96293 16.3773 7.23979 15.9417Z" fill="#2F3338"/>
                                </svg>
                            </a>
                            <a href={content.insta} target="_blank">
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M24 12C24 13.5759 23.6896 15.1363 23.0866 16.5922C22.4835 18.0481 21.5996 19.371 20.4853 20.4853C19.371 21.5996 18.0481 22.4835 16.5922 23.0866C15.1363 23.6896 13.5759 24 12 24C8.81741 24 5.76516 22.7357 3.51472 20.4853C1.26428 18.2348 0 15.1826 0 12C0 8.8174 1.26428 5.76516 3.51472 3.51472C5.76516 1.26428 8.81741 0 12 0C15.1826 0 18.2348 1.26428 20.4853 3.51472C22.7357 5.76516 24 8.8174 24 12ZM22.7517 11.9879C22.7517 9.13698 21.6192 6.40287 19.6033 4.387C17.5874 2.37112 14.8533 1.23862 12.0024 1.23862C9.15156 1.23862 6.41745 2.37112 4.40157 4.387C2.3857 6.40287 1.2532 9.13698 1.2532 11.9879C1.2532 14.8387 2.3857 17.5728 4.40157 19.5887C6.41745 21.6046 9.15156 22.7371 12.0024 22.7371C14.8533 22.7371 17.5874 21.6046 19.6033 19.5887C21.6192 17.5728 22.7517 14.8387 22.7517 11.9879Z" fill="#2F3338"/>
                                    <path d="M15 6H9C7.34315 6 6 7.34315 6 9V15C6 16.6569 7.34315 18 9 18H15C16.6569 18 18 16.6569 18 15V9C18 7.34315 16.6569 6 15 6Z" stroke="#33373C"/>
                                    <path d="M12 14.5C13.3807 14.5 14.5 13.3807 14.5 12C14.5 10.6193 13.3807 9.5 12 9.5C10.6193 9.5 9.5 10.6193 9.5 12C9.5 13.3807 10.6193 14.5 12 14.5Z" stroke="#33373C"/>
                                    <path d="M15.5 9.1501C15.859 9.1501 16.15 8.85908 16.15 8.5001C16.15 8.14111 15.859 7.8501 15.5 7.8501C15.141 7.8501 14.85 8.14111 14.85 8.5001C14.85 8.85908 15.141 9.1501 15.5 9.1501Z" stroke="#33373C" stroke-width="0.3"/>
                                </svg>
                            </a>
                        </div>
                    </div>
                )}
            </div>
            <div className="container">
                <div className="sc__main-ct">
                    {projects.length > 0 && (
                        <div className="sc__main-ct-box">
                            <div className="proj__sl-box fadeIn animated" data-count={projects.length}>
                                <div className="proj__sl-row">
                                    <div ref={sliderRef} className="keen-slider">
                                        {projects.map((pr, i) => (
                                            <div className="keen-slider__slide">
                                                <div className="proj__it">
                                                    <div className="proj__it-hd text-center">
                                                        <div className="proj__it-title">
                                                            {pr.title}
                                                        </div>
                                                        <div className="proj__it-address">
                                                            {pr.address}
                                                        </div>
                                                    </div>
                                                    <div className="proj__it-bl">
                                                        <div className="proj__it-img">
                                                            <img src={pr.img}/>
                                                        </div>
                                                        <div className={'proj__it-ct' + (isSubmitting2 ? ' proj__it-ct--disabled' : '')}>
                                                            <div className="proj__it-logo">
                                                                <img src={pr.logo}/>
                                                            </div>
                                                            <div className="proj__it-center">
                                                                {pr.open && pr.nav && (
                                                                    <div className="proj__it-nav">
                                                                        <ul>
                                                                            {pr.nav.map((prNav, j) => (
                                                                                <li>
                                                                                    {prNav.pr_nav_type === 'widjet' && (
                                                                                        <a onClick={()=>{
                                                                                            show(<Files title={prNav.pr_nav_title} file_manager_link={prNav.file_manager_link} close={hide}/>, "modal--frame");
                                                                                        }}>{prNav.pr_nav_title}</a>
                                                                                    )}

                                                                                    {prNav.pr_nav_type === 'ext' && (
                                                                                        <a href={prNav.ext_link} target="_blank">{prNav.pr_nav_title}</a>
                                                                                    )}

                                                                                    {prNav.pr_nav_type === 'ymap' && (
                                                                                        <a href={prNav.ymap_link} target="_blank">{prNav.pr_nav_title}</a>
                                                                                    )}

                                                                                    {prNav.pr_nav_type === 'pdf' && (
                                                                                        <a href={prNav.file} target="_blank">{prNav.pr_nav_title}</a>
                                                                                    )}

                                                                                    {prNav.pr_nav_type === 'vid' && (
                                                                                        <a onClick={()=>{
                                                                                            show(<Videos close={hide} project={pr.id}/>, "modal--video");
                                                                                        }}>{prNav.pr_nav_title}</a>
                                                                                    )}
                                                                                </li>
                                                                            ))}
                                                                        </ul>
                                                                    </div>
                                                                )}

                                                                {!pr.open && !isSubmitting2 && !isSent2 && pr.descr && (
                                                                    <div className="proj__it-descr">
                                                                        {pr.descr}
                                                                    </div>
                                                                )}

                                                                {!pr.open && isSent2 && (
                                                                    <div className="proj__it-descr">
                                                                        Ваша заявка принята! <br/>
                                                                        Менеджер свяжется с&nbsp;вами в&nbsp;ближайшее время.
                                                                    </div>
                                                                )}
                                                            </div>

                                                            {!pr.open && !isSent2 && !isSubmitting2 && (
                                                                <div className="proj__it-bottom">
                                                                    <div onClick={()=>{
                                                                        createProjectRequest(pr.id, pr.title);
                                                                    }} className="btn btn--dark btn--full d-flex align-items-center justify-content-center">
                                                                        отправить заявку
                                                                    </div>
                                                                </div>
                                                            )}

                                                            {pr.site && (
                                                                <div className="proj__it-bottom u-mt--20">
                                                                    <a href={pr.site} target="_blank">
                                                                        Перейти на сайт проекта
                                                                    </a>
                                                                </div>
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                                <div className="sl__nav-box">
                                    {loaded && instanceRef.current && (
                                        <div onClick={(e) =>
                                            e.stopPropagation() || instanceRef.current?.prev()
                                        } className={'sl__nav sl__nav--prev' + (currentSlide === 0 ? ' sl__nav--disabled' : '')}>
                                            <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <circle cx="7.21233" cy="7.21233" r="6.81233" stroke="#2F3338" strokeWidth="0.8"/>
                                                <path opacity="0.968" fillRule="evenodd" clipRule="evenodd" d="M8.61495 3.20557C8.55248 3.20557 8.48999 3.20557 8.42752 3.20557C8.40195 3.22736 8.37312 3.24562 8.34101 3.26035C7.18074 4.50423 6.0249 5.75377 4.87346 7.00894C4.78695 7.1446 4.78695 7.28023 4.87346 7.41589C6.0249 8.67107 7.18074 9.92059 8.34101 11.1645C8.36886 11.1873 8.3977 11.2056 8.42752 11.2193C8.48999 11.2193 8.55248 11.2193 8.61495 11.2193C8.75486 11.148 8.81975 11.028 8.80959 10.8593C8.79725 10.796 8.77323 10.7386 8.7375 10.6871C7.66115 9.53692 6.59181 8.37869 5.52948 7.21242C6.59181 6.04614 7.66115 4.88791 8.73751 3.73773C8.83636 3.60023 8.84116 3.45937 8.75192 3.31513C8.7117 3.26872 8.66603 3.2322 8.61495 3.20557Z" fill="#2F3338"/>
                                            </svg>
                                        </div>
                                    )}

                                    {loaded && instanceRef.current && (
                                        <div className="dots mb-only">
                                            {[
                                                ...Array(instanceRef.current.track.details.slides.length).keys(),
                                            ].map((idx) => {
                                                return (
                                                    <button
                                                        key={idx}
                                                        onClick={() => {
                                                            instanceRef.current?.moveToIdx(idx)
                                                        }}
                                                        className={"dot" + (currentSlide === idx ? " active" : "")}
                                                    ></button>
                                                )
                                            })}
                                        </div>
                                    )}

                                    {loaded && instanceRef.current && (
                                        <div onClick={(e) =>
                                            e.stopPropagation() || instanceRef.current?.next()
                                        } className={'sl__nav sl__nav--next' + (currentSlide === (projects.length - 1) ? ' sl__nav--disabled' : '')}>
                                            <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <circle cx="7.21248" cy="7.21248" r="6.81233" transform="rotate(-180 7.21248 7.21248)" stroke="#2F3338" strokeWidth="0.8"/>
                                                <path opacity="0.968" fillRule="evenodd" clipRule="evenodd" d="M5.80985 11.2192C5.87233 11.2192 5.93481 11.2192 5.99729 11.2192C6.02285 11.1974 6.05169 11.1792 6.0838 11.1645C7.24406 9.92057 8.3999 8.67104 9.55134 7.41586C9.63785 7.28021 9.63785 7.14457 9.55134 7.00892C8.3999 5.75374 7.24406 4.50421 6.0838 3.26032C6.05594 3.23749 6.02711 3.21924 5.99729 3.20554C5.93482 3.20554 5.87233 3.20554 5.80985 3.20554C5.66994 3.27679 5.60506 3.39679 5.61521 3.56553C5.62755 3.62883 5.65157 3.68622 5.6873 3.7377C6.76365 4.88788 7.833 6.04611 8.89532 7.21239C7.833 8.37866 6.76365 9.53689 5.6873 10.6871C5.58845 10.8246 5.58365 10.9654 5.67288 11.1097C5.71311 11.1561 5.75877 11.1926 5.80985 11.2192Z" fill="#2F3338"/>
                                            </svg>
                                        </div>
                                    )}
                                </div>


                            </div>
                        </div>
                    )}

                    {news.length > 0 && (
                        <div className="sc__main-ct-box fadeIn animated">
                            <div className="row">
                                <div className="col col-3 sc__box-left">
                                    <div className="sc__title">
                                        НОВОСТИ
                                    </div>
                                </div>
                                <div className="col col-9 sc__box-right">
                                    <div className="news__sl-box" data-count={news.length}>
                                        <div ref={sliderRef2} className="keen-slider">
                                            {news.map((newsIt, i) => (
                                                <div className="keen-slider__slide">
                                                    <div className="news__it">
                                                        <div className="news__it-date">
                                                            <span>{new Date(newsIt.date).toLocaleDateString('ru-RU',
                                                                { year: 'numeric', month: 'long', day: 'numeric' }
                                                            )}</span>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col col-4 news__it-left">
                                                                <div className="news__it-title">
                                                                    {parse(newsIt.title.rendered)}
                                                                </div>
                                                            </div>
                                                            <div className="col col-8 news__it-right">
                                                                <div className="news__it-descr">
                                                                    {parse(newsIt.excerpt.rendered)}
                                                                </div>
                                                                {newsIt.content.rendered && (
                                                                    <div className="news__it-actions">
                                                                        <a onClick={()=>{
                                                                            show(<Content subtitle={new Date(newsIt.date).toLocaleDateString('ru-RU',
                                                                                { year: 'numeric', month: 'long', day: 'numeric' }
                                                                            )} title={newsIt.title.rendered} text={newsIt.content.rendered} close={hide}/>, "modal--content");
                                                                        }}>читать дальше</a>
                                                                    </div>
                                                                )}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                        <div className="sl__nav-box">
                                            {loaded2 && instanceRef2.current && (
                                                <div onClick={(e) =>
                                                    e.stopPropagation() || instanceRef2.current?.prev()
                                                } className={'sl__nav sl__nav--prev' + (currentSlide2 === 0 ? ' sl__nav--disabled' : '')}>
                                                    <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <circle cx="7.21233" cy="7.21233" r="6.81233" stroke="#2F3338" strokeWidth="0.8"/>
                                                        <path opacity="0.968" fillRule="evenodd" clipRule="evenodd" d="M8.61495 3.20557C8.55248 3.20557 8.48999 3.20557 8.42752 3.20557C8.40195 3.22736 8.37312 3.24562 8.34101 3.26035C7.18074 4.50423 6.0249 5.75377 4.87346 7.00894C4.78695 7.1446 4.78695 7.28023 4.87346 7.41589C6.0249 8.67107 7.18074 9.92059 8.34101 11.1645C8.36886 11.1873 8.3977 11.2056 8.42752 11.2193C8.48999 11.2193 8.55248 11.2193 8.61495 11.2193C8.75486 11.148 8.81975 11.028 8.80959 10.8593C8.79725 10.796 8.77323 10.7386 8.7375 10.6871C7.66115 9.53692 6.59181 8.37869 5.52948 7.21242C6.59181 6.04614 7.66115 4.88791 8.73751 3.73773C8.83636 3.60023 8.84116 3.45937 8.75192 3.31513C8.7117 3.26872 8.66603 3.2322 8.61495 3.20557Z" fill="#2F3338"/>
                                                    </svg>

                                                </div>
                                            )}

                                            {loaded2 && instanceRef2.current && (
                                                <div className="dots mb-only">
                                                    {[
                                                        ...Array(instanceRef2.current.track.details.slides.length).keys(),
                                                    ].map((idx) => {
                                                        return (
                                                            <button
                                                                key={idx}
                                                                onClick={() => {
                                                                    instanceRef2.current?.moveToIdx(idx)
                                                                }}
                                                                className={"dot" + (currentSlide2 === idx ? " active" : "")}
                                                            ></button>
                                                        )
                                                    })}
                                                </div>
                                            )}

                                            {loaded2 && instanceRef2.current && (
                                                <div onClick={(e) =>
                                                    e.stopPropagation() || instanceRef2.current?.next()
                                                } className={'sl__nav sl__nav--next' + (currentSlide2 === (news.length - 1) ? ' sl__nav--disabled' : '')}>
                                                    <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <circle cx="7.21248" cy="7.21248" r="6.81233" transform="rotate(-180 7.21248 7.21248)" stroke="#2F3338" strokeWidth="0.8"/>
                                                        <path opacity="0.968" fillRule="evenodd" clipRule="evenodd" d="M5.80985 11.2192C5.87233 11.2192 5.93481 11.2192 5.99729 11.2192C6.02285 11.1974 6.05169 11.1792 6.0838 11.1645C7.24406 9.92057 8.3999 8.67104 9.55134 7.41586C9.63785 7.28021 9.63785 7.14457 9.55134 7.00892C8.3999 5.75374 7.24406 4.50421 6.0838 3.26032C6.05594 3.23749 6.02711 3.21924 5.99729 3.20554C5.93482 3.20554 5.87233 3.20554 5.80985 3.20554C5.66994 3.27679 5.60506 3.39679 5.61521 3.56553C5.62755 3.62883 5.65157 3.68622 5.6873 3.7377C6.76365 4.88788 7.833 6.04611 8.89532 7.21239C7.833 8.37866 6.76365 9.53689 5.6873 10.6871C5.58845 10.8246 5.58365 10.9654 5.67288 11.1097C5.71311 11.1561 5.75877 11.1926 5.80985 11.2192Z" fill="#2F3338"/>
                                                    </svg>
                                                </div>
                                            )}
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    )}

                    {content && content.docs && (
                        <div className="sc__main-ct-box box--docs fadeIn animated">
                            <div className="sc__title">
                                документы
                            </div>
                            <div className="docs__sl-box">
                                <div ref={sliderRef3} className="keen-slider">
                                    {Array.from(Array(Math.round(content.docs.length / 6)).keys()).map((docsSl, i) => (
                                        <div className="keen-slider__slide">
                                            <div className="docs__list">
                                                {paginate(content.docs, 6, i + 1).map((docIt, i) => (
                                                    <div className="docs__it">
                                                        {docIt.type.value === 'single' && (
                                                            <>
                                                                <div className="docs__it-title">
                                                                    {docIt.title}
                                                                </div>
                                                                <div className="docs__it-download">
                                                                    <a href={docIt.file} target="_blank">
                                                                        скачать pdf
                                                                    </a>
                                                                </div>
                                                            </>
                                                        )}

                                                        {docIt.type.value === 'multiple' && (
                                                            <div className="docs__it-title">
                                                                <a onClick={()=>{
                                                                    show(<Docs title={docIt.group} docs={docIt.files} close={hide}/>, "modal--docs");
                                                                }}>
                                                                    {parse(docIt.group)}
                                                                </a>
                                                            </div>
                                                        )}

                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>

                        </div>
                    )}
                </div>
            </div>
            {content && (
                <div className="sc__main-footer fadeIn animated mb-only">
                    <div className="side__phone text-center">
                        <a href={'tel:' + content.phone.replace(/-|_| /g, "").replace('(', '').replace(')', '')}>{content.phone}</a>
                    </div>
                    <div className="side__address text-center">
                        <a href={content.ymaps} target="_blank">
                            {content.address}
                        </a>
                    </div>
                    <div className="side__social">
                        <a href={content.telegram} target="_blank">
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M23.9997 11.9137C24.0104 13.4895 23.7106 15.0521 23.1174 16.5123C22.5242 17.9725 21.6493 19.3017 20.5426 20.424C19.4358 21.5463 18.119 22.4397 16.6672 23.0532C15.2154 23.6667 13.6571 23.9884 12.0813 23.9997C10.5055 24.011 8.94298 23.7119 7.48302 23.1193C6.02306 22.5267 4.69423 21.6524 3.5724 20.5461C2.45058 19.4398 1.55772 18.1234 0.944814 16.6718C0.331907 15.2203 0.0109527 13.6622 0.000275473 12.0863C-0.0104019 10.5105 0.289409 8.94788 0.882587 7.48767C1.47577 6.02746 2.3507 4.69827 3.45742 3.57598C4.56415 2.45369 5.881 1.56028 7.33279 0.946766C8.78458 0.333252 10.3429 0.0116455 11.9187 0.000310579C13.4945 -0.0110267 15.057 0.288129 16.517 0.880696C17.9769 1.47326 19.3058 2.34764 20.4276 3.45389C21.5494 4.56015 22.4423 5.87663 23.0552 7.32816C23.6681 8.77969 23.989 10.3378 23.9997 11.9137ZM22.9367 12.0393C22.9466 9.13894 21.8035 6.35318 19.7589 4.2949C17.7144 2.23663 14.9359 1.07443 12.0347 1.064C9.13346 1.05356 6.34715 2.19574 4.28872 4.23926C2.2303 6.28278 1.06837 9.06025 1.05854 11.9607C1.04872 14.8611 2.1918 17.6468 4.23633 19.7051C6.28087 21.7634 9.05936 22.9256 11.9606 22.936C14.8618 22.9464 17.6481 21.8043 19.7066 19.7607C21.765 17.7172 22.9269 14.9398 22.9367 12.0393Z" fill="#2F3338"/>
                                <path d="M17.505 6.96072C17.5307 6.98407 17.5495 7.01458 17.5595 7.04892C17.5695 7.08326 17.5703 7.12012 17.5617 7.15548C16.8057 10.2149 16.0441 13.28 15.277 16.3508C15.0998 17.052 14.5777 17.5608 13.9067 17.0666C13.0593 16.445 12.2158 15.8217 11.3763 15.1969C11.3486 15.1762 11.3151 15.1656 11.2812 15.1669C11.2473 15.1683 11.215 15.1814 11.1896 15.2042L9.76964 16.4482C9.74342 16.4713 9.71249 16.4879 9.67915 16.497C9.64581 16.506 9.61093 16.5072 9.5771 16.5004C9.54326 16.4936 9.51135 16.479 9.48374 16.4578C9.45612 16.4365 9.43351 16.4091 9.4176 16.3776L7.80153 13.1543C7.78215 13.1142 7.75525 13.0785 7.7224 13.0493C7.68956 13.02 7.6514 12.9978 7.61015 12.9838L4.87891 12.0344C4.82115 12.0142 4.77139 11.9754 4.73724 11.9239C4.7031 11.8725 4.68647 11.8112 4.68989 11.7495C4.70092 11.4898 4.82772 11.3153 5.07028 11.2261C9.07895 9.73774 13.0829 8.23643 17.0821 6.72213C17.1546 6.69454 17.2955 6.77407 17.505 6.96072ZM9.64914 13.7483L14.8494 9.13478C14.8982 9.09259 14.8903 9.07148 14.8258 9.07148C14.788 9.07311 14.7564 9.08122 14.7312 9.09583C12.682 10.3375 10.6249 11.5799 8.55995 12.8232C8.52138 12.8466 8.49272 12.8841 8.47961 12.9283C8.46649 12.9724 8.46987 13.02 8.48907 13.0617L9.55227 15.3161C9.66568 15.558 9.70978 15.5458 9.68458 15.2796L9.56408 13.9698C9.55974 13.9286 9.56521 13.8868 9.58003 13.8482C9.59485 13.8096 9.61857 13.7753 9.64914 13.7483Z" fill="#2F3338"/>
                            </svg>
                        </a>
                        <a href={content.youtube} target="_blank">
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M23.9998 11.9352C24.0079 13.5111 23.7054 15.0732 23.1097 16.5323C22.5141 17.9914 21.6369 19.3191 20.5283 20.4394C19.4197 21.5597 18.1013 22.4507 16.6485 23.0616C15.1956 23.6725 13.6368 23.9913 12.061 23.9998C8.8784 24.017 5.81978 22.7692 3.55794 20.5309C1.2961 18.2927 0.0163288 15.2473 0.000154915 12.0648C-0.00785207 10.4889 0.294604 8.92684 0.890256 7.4677C1.48591 6.00855 2.36309 4.68093 3.47172 3.56063C4.58035 2.44033 5.89871 1.54929 7.35154 0.938386C8.80436 0.327482 10.3632 0.00867709 11.939 0.000174891C15.1216 -0.0169957 18.1802 1.2308 20.4421 3.46905C22.7039 5.70731 23.9837 8.75269 23.9998 11.9352ZM22.9027 11.8922C22.8782 8.99789 21.7052 6.23249 19.6419 4.20433C17.5786 2.17617 14.794 1.05138 11.9005 1.0774C10.4678 1.09029 9.05157 1.38532 7.73258 1.94565C6.41359 2.50597 5.21771 3.32063 4.21323 4.34309C3.20874 5.36556 2.41532 6.57581 1.87827 7.90475C1.34121 9.23369 1.07104 10.6553 1.08317 12.0884C1.10768 14.9827 2.2806 17.7481 4.3439 19.7762C6.40721 21.8044 9.19188 22.9292 12.0853 22.9031C13.518 22.8903 14.9343 22.5952 16.2533 22.0349C17.5723 21.4746 18.7681 20.6599 19.7726 19.6375C20.7771 18.615 21.5705 17.4047 22.1076 16.0758C22.6446 14.7469 22.9148 13.3253 22.9027 11.8922Z" fill="#2F3338"/>
                                <path d="M19.8978 14.4606C19.8978 15.2273 19.6023 15.9625 19.0763 16.5046C18.5503 17.0467 17.8369 17.3512 17.093 17.3512H6.95891C6.21503 17.3512 5.50162 17.0467 4.97562 16.5046C4.44962 15.9625 4.15411 15.2273 4.15411 14.4606V9.54978C4.15411 8.78315 4.44962 8.04791 4.97562 7.50582C5.50162 6.96373 6.21503 6.65918 6.95891 6.65918H17.093C17.8369 6.65918 18.5503 6.96373 19.0763 7.50582C19.6023 8.04791 19.8978 8.78315 19.8978 9.54978V14.4606ZM13.8707 11.9712L10.3723 10.0409C10.3576 10.033 10.3412 10.0291 10.3247 10.0296C10.3081 10.0301 10.2919 10.0349 10.2777 10.0437C10.2635 10.0525 10.2517 10.0649 10.2435 10.0797C10.2353 10.0946 10.2309 10.1113 10.2308 10.1284V13.8869C10.231 13.9037 10.2354 13.9201 10.2435 13.9347C10.2515 13.9492 10.263 13.9614 10.2768 13.97C10.2906 13.9785 10.3062 13.9832 10.3221 13.9836C10.338 13.9839 10.3537 13.9799 10.3676 13.972L13.8707 11.9955C13.8832 11.9874 13.8832 11.9793 13.8707 11.9712Z" fill="#2F3338"/>
                            </svg>
                        </a>
                        <a href={content.vimeo} target="_blank">
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M24 12C24 15.1826 22.7357 18.2348 20.4853 20.4853C18.2348 22.7357 15.1826 24 12 24C8.8174 24 5.76515 22.7357 3.51471 20.4853C1.26428 18.2348 0 15.1826 0 12C0 8.8174 1.26428 5.76516 3.51471 3.51472C5.76515 1.26428 8.8174 0 12 0C15.1826 0 18.2348 1.26428 20.4853 3.51472C22.7357 5.76516 24 8.8174 24 12ZM22.9297 12C22.9297 9.10127 21.7781 6.32127 19.7284 4.27156C17.6787 2.22186 14.8987 1.07034 12 1.07034C10.5647 1.07034 9.14345 1.35305 7.8174 1.90231C6.49135 2.45158 5.28648 3.25665 4.27157 4.27156C3.25665 5.28648 2.45157 6.49135 1.90231 7.8174C1.35304 9.14345 1.07034 10.5647 1.07034 12C1.07034 13.4353 1.35304 14.8566 1.90231 16.1826C2.45157 17.5086 3.25665 18.7135 4.27157 19.7284C5.28648 20.7433 6.49135 21.5484 7.8174 22.0977C9.14345 22.647 10.5647 22.9297 12 22.9297C14.8987 22.9297 17.6787 21.7781 19.7284 19.7284C21.7781 17.6787 22.9297 14.8987 22.9297 12Z" fill="#2F3338"/>
                                <path d="M13.1482 8.91296C13.4352 6.80876 16.5173 4.70214 18.4026 6.13737C20.2489 7.54341 18.4074 11.3091 17.4198 12.8003C16.0949 14.8047 14.4593 16.5124 12.5133 17.9233C10.825 19.1469 9.48224 17.1619 9.05167 15.7607C8.4046 13.646 7.7478 11.5848 7.08127 9.57706C6.95964 9.21379 6.72773 9.1173 6.38555 9.28758L5.45386 9.75464C5.41774 9.77326 5.37615 9.77859 5.33621 9.76972C5.29627 9.76084 5.26046 9.73831 5.23493 9.70599L4.8749 9.23893C4.84729 9.2025 4.83299 9.15764 4.83431 9.11169C4.83564 9.06573 4.85252 9.0214 4.8822 8.98594C5.8001 7.91073 6.85017 6.98715 8.03241 6.21521C8.32595 6.02385 8.64461 5.89978 8.98842 5.84302C10.4918 5.59003 10.881 7.19068 11.0415 8.29751C11.2994 10.0506 11.7373 11.7567 12.3551 13.4157C12.3833 13.4893 12.4379 13.5499 12.5084 13.586C13.3987 14.0336 14.885 10.8298 15.031 10.3433C15.3837 9.16838 14.2842 8.39968 13.2382 8.97377C13.1684 9.0127 13.1384 8.99242 13.1482 8.91296Z" fill="#2F3338"/>
                            </svg>
                        </a>
                        <a href={content.itunes} target="_blank">
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M24 12C24 13.5759 23.6896 15.1363 23.0866 16.5922C22.4835 18.0481 21.5996 19.371 20.4853 20.4853C19.371 21.5996 18.0481 22.4835 16.5922 23.0866C15.1363 23.6896 13.5759 24 12 24C8.81741 24 5.76516 22.7357 3.51472 20.4853C1.26428 18.2348 0 15.1826 0 12C0 8.8174 1.26428 5.76516 3.51472 3.51472C5.76516 1.26428 8.81741 0 12 0C15.1826 0 18.2348 1.26428 20.4853 3.51472C22.7357 5.76516 24 8.8174 24 12ZM22.7517 11.9879C22.7517 9.13698 21.6192 6.40287 19.6033 4.387C17.5874 2.37112 14.8533 1.23862 12.0024 1.23862C9.15156 1.23862 6.41745 2.37112 4.40157 4.387C2.3857 6.40287 1.2532 9.13698 1.2532 11.9879C1.2532 14.8387 2.3857 17.5728 4.40157 19.5887C6.41745 21.6046 9.15156 22.7371 12.0024 22.7371C14.8533 22.7371 17.5874 21.6046 19.6033 19.5887C21.6192 17.5728 22.7517 14.8387 22.7517 11.9879Z" fill="#2F3338"/>
                                <path d="M10.4626 13.329L12.3011 13.3338C12.3395 13.3338 12.3772 13.3439 12.4105 13.3631C12.4437 13.3823 12.4714 13.4099 12.4905 13.4431L13.1876 14.6477C13.1953 14.6606 13.1995 14.6754 13.1997 14.6904C13.1999 14.7054 13.196 14.7202 13.1886 14.7333C13.1812 14.7464 13.1705 14.7573 13.1575 14.7649C13.1445 14.7725 13.1297 14.7765 13.1147 14.7765C11.0876 14.7943 9.06776 14.7959 7.05521 14.7813C6.3849 14.7765 5.88459 14.4122 6.26103 13.703C6.38732 13.465 6.58567 13.3444 6.85606 13.3411L8.5464 13.3266C8.58465 13.3265 8.62235 13.3162 8.65563 13.2966C8.6889 13.277 8.71658 13.2488 8.73584 13.2148L11.0431 9.17598C11.0599 9.14649 11.0686 9.11313 11.0681 9.07937C11.0677 9.04562 11.0582 9.01271 11.0406 8.98411C10.6877 8.40285 10.4861 8.04665 10.4359 7.9155C9.98903 6.74975 11.6235 6.38059 11.8518 7.50263C11.868 7.58359 11.9036 7.5933 11.9587 7.53178C12.3545 7.09219 12.7116 6.3563 13.4134 7.06304C13.6369 7.28648 13.6701 7.53582 13.513 7.81107L10.4116 13.2391C10.4064 13.2485 10.4037 13.259 10.4037 13.2697C10.4037 13.2804 10.4064 13.2908 10.4116 13.2999C10.4168 13.3091 10.4242 13.3165 10.4332 13.3216C10.4422 13.3268 10.4523 13.3293 10.4626 13.329Z" fill="#2F3338"/>
                                <path d="M15.4414 13.3799C16.2356 13.3994 18.2125 12.9889 17.7948 14.5117C17.7731 14.5894 17.7217 14.6556 17.6515 14.6963C17.224 14.9472 16.7593 14.9999 16.2574 14.8541C16.1505 14.8218 16.1287 14.8517 16.1918 14.944C16.697 15.6872 17.5179 16.6732 16.4566 17.1516C16.1149 17.3055 15.8494 17.2205 15.66 16.8966L12.6144 11.6726C12.5946 11.6388 12.5843 11.6002 12.5847 11.5609C12.5852 11.5215 12.5963 11.4829 12.6169 11.4492L13.4037 10.1814C13.4088 10.1729 13.4161 10.166 13.4247 10.1612C13.4334 10.1564 13.4431 10.1541 13.453 10.1543C13.4629 10.1546 13.4725 10.1574 13.4809 10.1626C13.4893 10.1678 13.4962 10.1751 13.5009 10.1838L15.2568 13.2706C15.2753 13.3032 15.3021 13.3303 15.3344 13.3495C15.3668 13.3686 15.4036 13.3791 15.4414 13.3799Z" fill="#2F3338"/>
                                <path d="M7.23979 15.9417C7.2635 15.9046 7.29641 15.8739 7.33546 15.8527C7.37451 15.8315 7.41845 15.8204 7.46324 15.8203H8.84758C8.97063 15.8203 8.99896 15.8721 8.93257 15.9757L8.30113 16.9448C8.05179 17.3269 7.75225 17.3714 7.40252 17.0784C7.01718 16.7562 6.96293 16.3773 7.23979 15.9417Z" fill="#2F3338"/>
                            </svg>
                        </a>
                        <a href={content.insta} target="_blank">
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M24 12C24 13.5759 23.6896 15.1363 23.0866 16.5922C22.4835 18.0481 21.5996 19.371 20.4853 20.4853C19.371 21.5996 18.0481 22.4835 16.5922 23.0866C15.1363 23.6896 13.5759 24 12 24C8.81741 24 5.76516 22.7357 3.51472 20.4853C1.26428 18.2348 0 15.1826 0 12C0 8.8174 1.26428 5.76516 3.51472 3.51472C5.76516 1.26428 8.81741 0 12 0C15.1826 0 18.2348 1.26428 20.4853 3.51472C22.7357 5.76516 24 8.8174 24 12ZM22.7517 11.9879C22.7517 9.13698 21.6192 6.40287 19.6033 4.387C17.5874 2.37112 14.8533 1.23862 12.0024 1.23862C9.15156 1.23862 6.41745 2.37112 4.40157 4.387C2.3857 6.40287 1.2532 9.13698 1.2532 11.9879C1.2532 14.8387 2.3857 17.5728 4.40157 19.5887C6.41745 21.6046 9.15156 22.7371 12.0024 22.7371C14.8533 22.7371 17.5874 21.6046 19.6033 19.5887C21.6192 17.5728 22.7517 14.8387 22.7517 11.9879Z" fill="#2F3338"/>
                                <path d="M15 6H9C7.34315 6 6 7.34315 6 9V15C6 16.6569 7.34315 18 9 18H15C16.6569 18 18 16.6569 18 15V9C18 7.34315 16.6569 6 15 6Z" stroke="#33373C"/>
                                <path d="M12 14.5C13.3807 14.5 14.5 13.3807 14.5 12C14.5 10.6193 13.3807 9.5 12 9.5C10.6193 9.5 9.5 10.6193 9.5 12C9.5 13.3807 10.6193 14.5 12 14.5Z" stroke="#33373C"/>
                                <path d="M15.5 9.1501C15.859 9.1501 16.15 8.85908 16.15 8.5001C16.15 8.14111 15.859 7.8501 15.5 7.8501C15.141 7.8501 14.85 8.14111 14.85 8.5001C14.85 8.85908 15.141 9.1501 15.5 9.1501Z" stroke="#33373C" stroke-width="0.3"/>
                            </svg>
                        </a>
                    </div>
                </div>
            )}
        </div>
    );
}

export default Home;

import parse from 'html-react-parser'
import React, {useState, useRef, useEffect} from "react";
import ReactPlayer from 'react-player/lazy'

function Videos({title = null, project = null}){
    const [loading, setLoading] = useState(true);
    const [groups, setGroups] = useState([]);
    const [videoPlaying, setVideoPlaying] = useState({});
    const [videoPlayingTitle, setVideoPlayingTitle] = useState(null);

    useEffect(()=>{
        getVideo();
    }, []);

    const getVideo = async() => {
        await fetch(process.env.REACT_APP_API_PROXY + 'wp/v2/getVideo' + (project ? '?project=' + project : ''), {
            method: "GET",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('auth_key')}`
            }
        })
            .then((response) => response.json())
            .then(response => {
                setGroups(response);
                setLoading(false);
            });
    }

    return (
        <>
            {title && (
                <div className="modal__title modal__title--large">
                    {parse(title)}
                </div>
            )}
            {groups.length === 0 && loading && (
                <div className="modal__loader d-flex align-items-center justify-content-center">
                    <div className="modal__loader-icon">
                        <img src={require("../../assets/img/loader.svg").default}/>
                    </div>
                </div>
            )}

            {groups.length > 0 && (
                <div className="modal__title modal__title--large">
                    {videoPlayingTitle ?? parse(groups[0].title)}
                </div>
            )}

            {groups.length > 0 && (
                <div className="video__wrap">
                    {Object.keys(videoPlaying).length === 0 && (
                        <div className="video__scroll">
                            {groups.map((gr, i) => (
                                gr.items.length > 0 && (
                                    <div key={'v-' + i} className="video__group">
                                        <div className="video__group-title">
                                            {i == 0 ? gr.subtitle : gr.title}
                                        </div>
                                        <div className="video__grid fadeIn animated">
                                            {gr.items.map((it, j) => (
                                                <div className="video__it">
                                                    <div onClick={()=>{
                                                        setVideoPlayingTitle(i === 0 ? gr.subtitle : gr.title);
                                                        setVideoPlaying(it);
                                                    }} className="video__it-prev">
                                                        <img src={it.prev}/>
                                                        <div className="video__play">
                                                            <svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path d="M18 39V12L38 25.814L18 39Z" stroke="white" strokeWidth="2"/>
                                                            </svg>
                                                        </div>
                                                    </div>
                                                    <div className="d-flex align-items-center justify-content-between">
                                                        <div className="video__it-title">
                                                            {it.title}
                                                        </div>
                                                        <a href={it.file} download target="_blank" className="video__load">
                                                            <svg width="11" height="10" viewBox="0 0 11 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path opacity="0.961" fillRule="evenodd" clipRule="evenodd" d="M5.31418 0.000836923C5.49454 -0.00900878 5.61707 0.0680154 5.68179 0.231907C5.68878 2.33255 5.6958 4.43319 5.7028 6.53383C6.19004 6.03959 6.68369 5.55293 7.18375 5.07388C7.44906 4.9928 7.6031 5.08733 7.64589 5.35747C7.64944 5.4554 7.61793 5.53943 7.55136 5.60955C6.91066 6.25024 6.26997 6.89094 5.62927 7.53163C5.46122 7.68567 5.29317 7.68567 5.12512 7.53163C4.47743 6.88394 3.82972 6.23623 3.18203 5.58854C3.09275 5.45347 3.08576 5.31344 3.16102 5.16841C3.30363 5.02412 3.46118 5.00662 3.63367 5.11589C4.10631 5.58854 4.57895 6.06118 5.0516 6.53383C5.05859 4.43319 5.06561 2.33255 5.0726 0.231907C5.10945 0.107493 5.18999 0.0304685 5.31418 0.000836923Z" fill="white"/>
                                                                <path opacity="0.96" fillRule="evenodd" clipRule="evenodd" d="M10.7553 5.06312C10.7553 6.12745 10.7553 7.19176 10.7553 8.25609C10.5455 9.28854 9.91527 9.86621 8.8647 9.98912C6.53998 10.0031 4.21529 10.0031 1.89058 9.98912C0.840008 9.86621 0.209816 9.28854 0 8.25609C0 7.17777 0 6.09943 0 5.02111C0.114281 4.82965 0.275331 4.77714 0.483147 4.86356C0.55392 4.91324 0.599432 4.97977 0.619689 5.06312C0.62669 6.09943 0.633694 7.13576 0.640695 8.17207C0.76173 8.81688 1.14335 9.2055 1.78554 9.33792C4.18027 9.35193 6.575 9.35193 8.96973 9.33792C9.61171 9.20636 9.99333 8.81774 10.1146 8.17207C10.1149 7.12752 10.1289 6.0842 10.1566 5.04211C10.2682 4.87505 10.4188 4.82955 10.6082 4.90557C10.6694 4.9479 10.7184 5.00041 10.7553 5.06312Z" fill="white"/>
                                                            </svg>
                                                        </a>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                )
                            ))}
                        </div>
                    )}

                    {Object.keys(videoPlaying).length > 0 && (
                        <>
                            <div className="video__wrap-box">
                                <div className="video__player fadeIn animated">
                                    <ReactPlayer controls={true} url={videoPlaying.src} playing={true} playsinline={true}/>
                                </div>
                                <div className="d-flex align-items-center justify-content-between">
                                    <div onClick={()=>{
                                        setVideoPlayingTitle(null);
                                        setVideoPlaying({});
                                    }} className="video__back">
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 1024">
                                            <path fill="#ffffff" d="M224 480h640a32 32 0 1 1 0 64H224a32 32 0 0 1 0-64z"/>
                                            <path fill="#ffffff" d="m237.248 512 265.408 265.344a32 32 0 0 1-45.312 45.312l-288-288a32 32 0 0 1 0-45.312l288-288a32 32 0 1 1 45.312 45.312L237.248 512z"/>
                                        </svg>
                                    </div>
                                    <div className="video__it-title">
                                        {videoPlaying.title}
                                    </div>
                                    <a href={videoPlaying.file} download target="_blank" className="video__load">
                                        <svg width="11" height="10" viewBox="0 0 11 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path opacity="0.961" fillRule="evenodd" clipRule="evenodd" d="M5.31418 0.000836923C5.49454 -0.00900878 5.61707 0.0680154 5.68179 0.231907C5.68878 2.33255 5.6958 4.43319 5.7028 6.53383C6.19004 6.03959 6.68369 5.55293 7.18375 5.07388C7.44906 4.9928 7.6031 5.08733 7.64589 5.35747C7.64944 5.4554 7.61793 5.53943 7.55136 5.60955C6.91066 6.25024 6.26997 6.89094 5.62927 7.53163C5.46122 7.68567 5.29317 7.68567 5.12512 7.53163C4.47743 6.88394 3.82972 6.23623 3.18203 5.58854C3.09275 5.45347 3.08576 5.31344 3.16102 5.16841C3.30363 5.02412 3.46118 5.00662 3.63367 5.11589C4.10631 5.58854 4.57895 6.06118 5.0516 6.53383C5.05859 4.43319 5.06561 2.33255 5.0726 0.231907C5.10945 0.107493 5.18999 0.0304685 5.31418 0.000836923Z" fill="white"/>
                                            <path opacity="0.96" fillRule="evenodd" clipRule="evenodd" d="M10.7553 5.06312C10.7553 6.12745 10.7553 7.19176 10.7553 8.25609C10.5455 9.28854 9.91527 9.86621 8.8647 9.98912C6.53998 10.0031 4.21529 10.0031 1.89058 9.98912C0.840008 9.86621 0.209816 9.28854 0 8.25609C0 7.17777 0 6.09943 0 5.02111C0.114281 4.82965 0.275331 4.77714 0.483147 4.86356C0.55392 4.91324 0.599432 4.97977 0.619689 5.06312C0.62669 6.09943 0.633694 7.13576 0.640695 8.17207C0.76173 8.81688 1.14335 9.2055 1.78554 9.33792C4.18027 9.35193 6.575 9.35193 8.96973 9.33792C9.61171 9.20636 9.99333 8.81774 10.1146 8.17207C10.1149 7.12752 10.1289 6.0842 10.1566 5.04211C10.2682 4.87505 10.4188 4.82955 10.6082 4.90557C10.6694 4.9479 10.7184 5.00041 10.7553 5.06312Z" fill="white"/>
                                        </svg>
                                    </a>
                                </div>
                            </div>
                        </>
                    )}
                </div>
            )}
        </>
    );
}

export default Videos;

import parse from 'html-react-parser'

function Files({file_manager_link, title}){
    return (
        <>
            {file_manager_link && (
                <div className="modal__frame">
                    <iframe src={file_manager_link} frameBorder={0}/>
                </div>
            )}
        </>
    );
}

export default Files;

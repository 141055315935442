import { Routes, Route } from 'react-router-dom'
import React from 'react';
import {ModalProvider} from "./context";
import Home from './pages/Home'
import Login from './pages/Login'
import Configurator from "./pages/Configurator";

function App() {
  return (
      <ModalProvider>
          <div id="wrapper" className="wrapper">
              <Routes>
                  <Route path="/" element={<Home />} />
                  <Route path="/configurator" element={<Configurator />} />
                  <Route path="/login" element={<Login />} />
              </Routes>
          </div>
      </ModalProvider>
  );
}

export default App;

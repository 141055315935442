import React, {useEffect, useState} from 'react'

import parse from 'html-react-parser';
import Countdown from 'react-countdown';
import { useForm, SubmitHandler } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import InputMask from "react-input-mask";
import * as yup from "yup";
import {useModal} from "../../context";

function Sms({phone, sendReg}){
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [countDown, setCountDown] = useState(true);
    const { show, hide} = useModal();
    const schemaObj = {
        code: yup.string()
            .test("len", 'Некорректный код', (val) => {
                const val_length_without_dashes = val.replace(/-|_| /g, "").length;
                return val_length_without_dashes === 6;
            })
            .required('Некорректный код'),
    };
    const schema = yup.object(schemaObj).required();
    const { register, getValues, clearErrors, setValue, watch, setFocus, setError, formState: { errors }, handleSubmit } = useForm({
        resolver: yupResolver(schema)
    });

    const onSubmit = async (data) => {
        checkCode(data.code);
    };

    const resendSms = async () => {
        setIsSubmitting(true);
        const formData = new FormData();
        formData.append("phone", phone);
        await fetch(process.env.REACT_APP_API_PROXY + 'wp/v2/resendSms', {
            method: "POST",
            body: formData,
        }).then((response) => {
            if(response.ok){
                return response.json().then((data) => {
                    if(data){
                        setIsSubmitting(false);
                        setCountDown(true);
                    }
                });
            }
            setIsSubmitting(false);
        }).catch((error) => {
            console.log(error);
            setIsSubmitting(false);
        });
    }

    const checkCode = async(code) => {
        setIsSubmitting(true);
        const formData = new FormData();
        formData.append("code", code);
        formData.append("phone", phone);
        await fetch(process.env.REACT_APP_API_PROXY + 'wp/v2/checkCode', {
            method: "POST",
            body: formData,
        }).then((response) => {
            if(response.ok){
                return response.json().then((data) => {
                    if(data){
                        setCountDown(false);
                        sendReg();
                    }else{

                    }
                    setIsSubmitting(false);
                });
            }else{
                return response.json().then((error) => {
                    switch(error.code.toString()){
                        case '409':
                            setError('code', { type: 'custom', message: error.message});
                            break;
                    }
                    setIsSubmitting(false);
                });

            }
        }).catch((error) => {
            console.log(error);
            setIsSubmitting(false);
        });
    }

    const renderer = ({ seconds, completed }) => {
        if (completed) {
            return (
                <>&nbsp;
                    <a onClick={()=>{
                        resendSms();
                        setCountDown(false);
                    }}>Отправить код еще раз</a>
                </>
            );
        } else {
            return <span>Повторная отправка через {seconds} сек</span>;
        }
    };

    return (
        <>
            <form onSubmit={handleSubmit(onSubmit)}  className={'code__form' + (isSubmitting ? ' form--loading' : '')}>
                <div className="code__form-title">
                    код подтверждения
                </div>
                <div className={'code__form-field' + (errors.code ? ' has-error' : '')}>
                    <InputMask
                        autoComplete="nope"
                        type="text"
                        id="code"
                        placeholder="XXXXXX"
                        mask="999999"
                        alwaysShowMask={false}
                        {...register("code")}
                    />

                    {countDown && (
                        <div className="code__form-hint fadeIn animated">
                            <Countdown
                                date={Date.now() + 59000}
                                renderer={renderer}
                            />
                        </div>
                    )}
                </div>
                {errors.code && (
                    <p className="error__message">
                        {errors.code?.message}
                    </p>
                )}
                <div className="code__form-descr">
                    Введите код подтверждения, отправленный на телефон
                </div>
                <button type="submit" className="btn btn--full btn--dark tbn--small d-flex align-items-center justify-content-center">
                    отправить
                </button>
            </form>
        </>
    );
}

export default Sms;

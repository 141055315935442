function Plan({plan}){
    return (
        <>
            <div className="pres__img">
                <img src={plan}/>
            </div>
        </>
    );
}

export default Plan;

import React, {useEffect, useState} from 'react'
import parse from 'html-react-parser';
import { useForm, SubmitHandler } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import {useModal} from "../../context";
import Info from "./Info";
import Sms from "./Sms";

function Recovery(){
    const [isSubmitting, setIsSubmitting] = useState(false);
    const { show, hide} = useModal();
    const schemaObj = {
        email: yup.string().email('Некорректный E-mail').required('Обязательно к заполнению'),
    };
    const schema = yup.object(schemaObj).required();
    const { register, getValues, clearErrors, setValue, watch, setFocus, setError, formState: { errors }, handleSubmit } = useForm({
        resolver: yupResolver(schema)
    });

    const onSubmit = async (data) => {
        setIsSubmitting(true);
        const formData = new FormData();
        formData.append("email", data.email.toLowerCase());

        await fetch(process.env.REACT_APP_API_PROXY + 'wp/v2/recovery', {
            method: "POST",
            body: formData,
        }).then((response) => {
            if(response.ok){
                return response.json().then((data) => {
                    if(data){
                        show(<Info text="Новый пароль отправлен на указанный вами e-mail." close={hide}/>, "modal--info");
                    }else{

                    }
                    setIsSubmitting(false);
                });
            }else{
                return response.json().then((error) => {
                    console.log(error);
                    switch(error.code.toString()){
                        case '406':
                            setError('email', { type: 'custom', message: error.message});
                            break;
                    }
                    setIsSubmitting(false);
                });

            }
        }).catch((error) => {
            console.log(error);
            setIsSubmitting(false);
        });
    };

    return (
        <>
            <form onSubmit={handleSubmit(onSubmit)}  className={'code__form' + (isSubmitting ? ' form--loading' : '')}>
                <div className="code__form-title">
                    Восстановление пароля
                </div>
                <div className={'code__form-field' + (errors.email ? ' has-error' : '')}>
                    <input
                        type="text"
                        id="email"
                        autoComplete="username"
                        placeholder="E-mail"
                        {...register("email")}
                    />
                </div>
                {errors.email && (
                    <p className="error__message">
                        {errors.email?.message}
                    </p>
                )}
                <div className="code__form-descr">
                    Введите email, указанный при регистрации
                </div>
                <button type="submit" className="btn btn--full btn--dark tbn--small d-flex align-items-center justify-content-center">
                    отправить
                </button>
            </form>
        </>
    );
}

export default Recovery;

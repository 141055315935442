import parse from 'html-react-parser'
import React, {useEffect, useState} from "react";
import {useKeenSlider} from "keen-slider/react";
import {useModal} from "../../context";
import Docs from "../../components/modals/Docs";

function paginate(array, page_size, page_number) {
    return array.slice((page_number - 1) * page_size, page_number * page_size);
}

function Rules({faq, docs}){
    const [faqCur, setFaqCur] = useState(-1);
    const [loaded3, setLoaded3] = useState(false);
    const [currentSlide3, setCurrentSlide3] = useState(0);
    const [sliderRef3, instanceRef3] = useKeenSlider({
        initial: 0,
        slideChanged(slider) {
            setCurrentSlide3(slider.track.details.rel)
        },
        created() {
            setLoaded3(true);
            window.dispatchEvent(new Event('resize'));
        },
    });

    const { show, hide} = useModal();

    useEffect(()=> {
        window.dispatchEvent(new Event('resize'));
    }, []);
    
    return (
        <>
            <div className="modal__bl">
                <div className="modal__title modal__title--large">
                    Правила работы
                </div>
                <div className="faq__box">
                    <div className="faq__box-scroll">
                        <div className="faq__box-list">
                            {faq.map((fq, i) => (
                                <div className={'faq__it d-flex align-items-start' + (i === faqCur ? ' active' : '')}>
                                    <div onClick={()=>{
                                        setFaqCur(i);
                                    }} className="faq__it-icon">
                                        <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M23.75 12.0762C23.75 18.5655 18.4893 23.8262 12 23.8262C5.51065 23.8262 0.25 18.5655 0.25 12.0762C0.25 5.58683 5.51065 0.326172 12 0.326172C18.4893 0.326172 23.75 5.58683 23.75 12.0762Z" stroke="white" strokeWidth="0.5"/>
                                            <path d="M4 12.0762H20" stroke="white" strokeWidth="0.5"/>
                                            <path d="M12 4.07617L12 20.0762" stroke="white" strokeWidth="0.5"/>
                                        </svg>
                                    </div>
                                    <div className="faq__it-ct">
                                        <div onClick={()=>{
                                            setFaqCur(i);
                                        }} className="faq__it-title">
                                            {parse(fq.quest)}
                                        </div>
                                        <div className="faq__it-answ fadein animated">
                                            <div className="faq__it-text">
                                                {parse(fq.answ)}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
            <div className="modal__bl">
                <div className="sc__title">
                    документы
                </div>
                {docs && (
                    <div className="docs__sl-box">
                        <div ref={sliderRef3} className="keen-slider">
                            {Array.from(Array(Math.round(docs.length / 6)).keys()).map((docsSl, i) => (
                                <div className="keen-slider__slide">
                                    <div className="docs__list">
                                        {paginate(docs, 6, i + 1).map((docIt, i) => (
                                            <div className="docs__it">
                                                {docIt.type.value === 'single' && (
                                                    <>
                                                        <div className="docs__it-title">
                                                            {docIt.title}
                                                        </div>
                                                        <div className="docs__it-download">
                                                            <a href={docIt.file} target="_blank">
                                                                скачать pdf
                                                            </a>
                                                        </div>
                                                    </>
                                                )}

                                                {docIt.type.value === 'multiple' && (
                                                    <div className="docs__it-title">
                                                        <a onClick={()=>{
                                                            show(<Docs title={docIt.group} docs={docIt.files} close={hide}/>, "modal--docs");
                                                        }}>
                                                            {parse(docIt.group)}
                                                        </a>
                                                    </div>
                                                )}

                                            </div>
                                        ))}
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                )}
            </div>
        </>
    );
}

export default Rules;
